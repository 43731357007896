<template>
<div>


  <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :id="'popover-refresh-list-' + row.item.id">
    <div class="custom-switch-inner" @click.prevent="openSchedulePopover(row.item)">

      <input type="checkbox" data-toggle="tooltip" data-placement="top" title="Auto Refresh"

             :id="'customSwitch-dt-' + row.item.id" :data-id="row.item.id" :data-name="row.item.list_name"
             :checked="switched  && canRefreshList(row.item)"
             class="custom-control-input bg-primary auto_refresh_list"
             :class="switched && canRefreshList(row.item) ? 'checked' : ''"
             :disabled="!canRefreshList(row.item)">
      <label :for="'customSwitch-dt-' + row.item.id" data-on-label="ON" data-off-label="OFF"
             class="custom-control-label"></label>
    </div>

    <div v-show="switched">
      <span class="badge-light rounded mx-1 px-2 py-1"
            style=" background-color: #ffdceb !important;
                    color: #e60073 !important;
                    font-size: 0.7em;
                    font-weight: 400;"> {{  row.item.auto_refresh }} Days </span>
    </div>
  </div>

  <div
      :ref="'popover-click-outside-' + row.item.id"
      @click="closePopover( row.item)"
      style="background-color: rgb(105 102 114 / 10%);position: absolute;inset: -100vh -200vw -100vh -200vh;z-index: 1050;display: none;">

  </div>

  <b-popover
      :target="'popover-refresh-list-' +row.item.id "
      :ref="'popover-refresh-list-' +row.item.id "
      placement="auto"
      :container="'status-container-' + row.item.id"
      :delay="{ show: 1000, hide: 950 }"
  >

    <SetDripWarmingRefreshModal :list="row.item"
                     @disableAutoRefreshSuccess="finishAutoRefresh"
                     @disableAutoRefreshFailed="finishAutoRefresh"

                     @autoRefreshSuccess="finishAutoRefresh"
                     @autoRefreshFailed="finishAutoRefresh"
    ></SetDripWarmingRefreshModal>


  </b-popover>
</div>


</template>
<script>

import api from '@/api/RestClient'
import SetDripWarmingRefreshModal from "../../../modals/SetDripWarmingRefreshModal.vue";
export default {
    name: 'DtListSwitchRefreshCell',
    props: ['row'],
  components : {
    SetDripWarmingRefreshModal,

  },

  data(){
      return {
        isAutoRefreshActive : false
      }
  },
    methods: {
        canRefreshList(item) {

            try {
                if (!item.platform || item.platform.trim() == '') return true;

                let platform = item.platform.toLowerCase();
                return !['webhook', 'inboxsuite'].includes(platform);
            } catch (e) {
                console.error(e);
                return null;
            }
        },

      openSchedulePopover(list){
        this.$refs['popover-click-outside-' + list.id].style.display= 'initial';

        this.$refs['popover-refresh-list-' + list.id].$emit('open');
      },

      closePopover(list){
        if(this.$refs['popover-refresh-list-' + list.id]){

          this.$refs['popover-click-outside-' + list.id].style.display= 'none';
          this.$refs['popover-refresh-list-' + list.id].$emit('close');
        }

      },

      finishAutoRefresh(refreshAction,success,days)
      {

        console.log(refreshAction,success,days);

        if(refreshAction === 'set' && success) {
          this.row.item.auto_refresh = days;

          this.$bvToast.toast(`successfully set the auto refresh to ${days}`, {
            variant : 'success',
            title : 'Success'
          });

        }else if(!success)
        {
          this.$bvToast.toast('failed to set the auto refresh', {
            variant : 'danger',
            title : 'Failed'
          });
        }

        if(refreshAction === 'disable' && success) {
          this.row.item.auto_refresh = 0;

          this.$bvToast.toast(`the auto refresh is disabled successfully`, {
            variant : 'success',
            title : 'Success'
          });

        }else if(!success){
          this.$bvToast.toast('failed to disable the auto refresh', {
            variant : 'danger',
            title : 'Failed'
          });
      }

        this.closePopover(this.row.item);
      }

    },
    computed : {
      switched(){
        return this.row.item.auto_refresh > 0 ;
      }
    }
}

</script>