<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <b-row class="mt-4">
                            <b-col md="4">
                                <b-form-group label="From" label-for="from">
                                    <b-form-input id="from" type="date" v-model="dates.from" :max="dates.to"
                                        :value="dates.from"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="to">
                                    <b-form-input id="to" type="date" v-model="dates.to" :min="dates.from"
                                        :max="dates.max" :value="dates.to"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="submit">
                                    <b-button class="action" variant="primary" :disabled="submitload" @click="onSubmit">
                                        <b-spinner small type="grow" v-if="submitload"></b-spinner>
                                        Submit
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </template>

                    <template v-slot:headerAction class="mt-3">

                        <b-form v-if="is_manager" class="mx-3">
                            <AccountsMultiselect @onComplete="onAccountsLoaded" @onSelect="onSelectAccount" />
                        </b-form>

                        <b-button variant="light" class="mr-2" :disabled="btnAddIsDisabled"
                            v-b-modal.LoDailyLimitAutoUpdate>
                            <i class="fas fa-magic fa-lg mx-auto"></i> Daily Limit Auto Update
                        </b-button>


                        <!-- New Integration Wizard modal btn -->
                        <b-button id="addNewBtn"
                            ref="addNewBtn"
                            @click="showModalAddIntegration($event, 'add-integration-wizard-modal')"
                            variant="primary"
                            class="mx-2"
                            :disabled="btnAddIsDisabled">Add New
                        </b-button>
                    </template>

                    <template v-slot:body>

                        <b-row>
                            <b-col lg="12">
                                <div class="container-fluid">
                                    <b-row style="justify-content: end; padding:10px 0px;">
                                        <b-col lg="11" md="11" v-show="isFilter" class="alert-secondary">
                                            <Transition name="fade">
                                                <filters ref="integrations-filters" @onFilter="onFilter"
                                                    :integrationFilterColumns="integrationColumns"
                                                    @onSelectedColumns="SelectedColumns"
                                                    :platforms_array="platforms_co"></filters>
                                            </Transition>
                                        </b-col>
                                        <b-col lg="1" class="mt-2">
                                            <b-button v-b-tooltip.hover title="Filter" variant="primary"
                                                @click="toggleInputsFilter"> <i class="fa fa-filter mx-0"></i>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12">
                                <DripWarmingIntegrationsDataTable ref="dt-integrations" :details="consumption_details"
                                    @onShowInvoiceHistories="onShowInvoiceHistories" @onFinishedFilter="finishedFilter"
                                    @onFilterIntegrationColum="filterIntegrationColum" />
                            </b-col>
                        </b-row>

                        <b-row class="charts" v-if="!isAdmin && showChart">
                            <Ibgmorris :item="charts" ref="ibgmorris">
                            </Ibgmorris>
                        </b-row>

                        <b-row class="adminCharts" v-if="isAdmin && loadAdminStats">
                            <ApexCharts :params="params" :series="adminEventsChart.series"
                                :categories="adminEventsChart.categories" :columnsHide="apexColumnsHide"
                                :entity="entity" ref="ibgapexcharts"></ApexCharts>
                        </b-row>

                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <ModalTimeLine :integrationId="integration_id" ></ModalTimeLine>

        <LoDailyLimitAutoUpdate :account="is_manager && admin.account ? getCurrentAccountID(admin) : null">
        </LoDailyLimitAutoUpdate>

        <!-- New Add Integration Wizard Modal -->
        <AddIntegrationWizardModal
            v-if="renderModalAdd"
            id="add-integration-wizard-modal"
            ref="add-integration-wizard-modal"
            :account="getCurrentAccountID(admin)"
            @hide="forceRerenderModalAdd"
            @integrationCreated="refreshTables"/>
    </b-container>
</template>

<script>
import { sofbox } from "@/config/pluginInit";

import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import ModalAddIntegration from "./modals/ModalAddIntegration.vue";
import DtIntegrations from "./children/DtIntegrations.vue";
import DripWarmingIntegrationsDataTable from "./DripWarmingIntegrationsDataTable.vue";
import IntegrationsDataTable from "../LiveReports/children/IntegrationsDataTable";
import filters from "./children/filtersIntegration.vue";
import DtLiveEventReport from "../LiveReports/children/DtLiveEventsReport.vue";
import Ibgmorris from '../../components/inboxgeek/IbgMorris'
import ApexCharts from "../LiveReports/children/ApexCharts.vue"
import { mapGetters } from 'vuex'
import { helper } from '@/helpers'
import _ from 'lodash'
import LoDailyLimitAutoUpdate from "@/components/inboxgeek/modals/IntgrationModal/LoDailyLimitAutoUpdate.vue";
import ModalTimeLine from "./modals/ModalTimeLine";
import AddIntegrationWizardModal from "@/components/inboxgeek/modals/Wizards/DwIntegration/AddIntegrationWizardModal";

import api from '@/api/RestClient'
// import Multiselect from 'vue-multiselect'
import moment from 'moment'
const IS_MANAGER = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);

const SERVICE_ID = 1;

export default {
    name: "DripWarmingIntegration",
    components: {
        AccountsMultiselect,
        DtIntegrations,
      DripWarmingIntegrationsDataTable,
        ModalAddIntegration,
        filters,
        DtLiveEventReport,
        Ibgmorris,
        ApexCharts,
        LoDailyLimitAutoUpdate,
        ModalTimeLine,
        AddIntegrationWizardModal
    },
    created() {
        let _url = window.location.href.replace('127.0.0.1', 'localhost')
        if (window.location.host.split(":")[0] == '127.0.0.1') window.location.href = _url
    },
    mounted() {
        sofbox.index();
        this.initDates();

        this.$root.$on('showDripChart', (item) => {
            this.item = item;
            if (this.isAdmin) this.adminGraph(item);
            else this.graph(item);
        });

        this.$root.$on('updatedTable', (item) => {
            this.submitload = false;
        });

        this.$root.$on('hideChart', (item) => {
            this.showChart = false;
            this.loadAdminStats = false;
        });

        this.renderModalAdd = true;
        this.isManager();
        this.refreshTables();
        this.getConsumptionDetails();
    },
    methods: {
        isManager() {
            this.is_manager = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
        },
        refreshTables() {
            console.log('refreshing');
          this.$root.$emit('bv::refresh::table', 'dt-live-reports');

            this.$root.$on('reloadIntegrationsAndList', () => {
                this.$root.$emit('bv::refresh::table', this.$refs['dt-lists'] ?.id)
                this.$root.$emit('bv::refresh::table', this.$refs['dt-integrations'] ?.id)

            })
        },
        // To auth2 - review script
        manageActionFromList() {
            const COMP = this;
            let action = COMP.$route.query.action;
            let uriData = {
                ...COMP.$route.query
            };

            if (action) {
                if (['aweber_integration', 'keap_integration', 'mailchimp_integration', 'hubspot_integration'].includes(action)) {
                    if (uriData ?.error) COMP.$swal('Authorization IG', 'IG App ' + uriData ?.error, 'warning');
                    else COMP.$swal('Authorization IG', 'IG App successfully authorized', 'success');
                    localStorage.setItem('oauth.params', JSON.stringify(uriData));
                    localStorage.setItem('oauth.refresh', true);
                    window.opener.postMessage({ "data" : uriData }, process.env.VUE_APP_URL);
                    window.close();
                }
            }
        },
        forceRerenderModalAdd() {
            this.renderModalAdd = false;
            this.$nextTick(() => {
                this.renderModalAdd = true
            })
            // Fixed set account after hide modal
            setTimeout(() => {
                this.onSelectAccount(this.admin.account)
            }, 500)
        },
        onAccountsLoaded(data) {
            const that = this;
            setTimeout(() => {
                that.manageActionFromList(that)
            }, 500);
        },
        onSelectAccount(accountSelected) {

            if (this.is_manager) {
                if (accountSelected) {
                    this.btnAddIsDisabled = false
                    this.admin.account = accountSelected

                    this.$refs['modal-add-integration'].setOrganization(accountSelected)
                    this.$refs['dt-integrations'].setAccount(accountSelected.code)
                } else {
                    delete this.admin.account
                    this.btnAddIsDisabled = true
                    this.$refs['dt-integrations'].setAccount(null)
                }
            }
            this.showChart = false;
            this.loadAdminStats = false;
        },
        getCurrentAccountID() {
            var accountId = (typeof this.admin.account.code !== "undefined") ? this.admin.account.code : null;
            accountId = (this.is_manager && accountId) ? accountId : null
            return accountId;
        },
        showModalAddIntegration(e, modalName = 'modal-add-integration') {
            e.preventDefault();

            // Check Inactive Account
            if(this.user && this.user.account && !this.user.account.status) {
                helper.alertAccountInactive(this.$swal);
                return;
            }

            let checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

            if (checkShowAlert.state && checkShowAlert.alert) {
                this.$swal(checkShowAlert.alert);
                return;
            } else {
                if (this.isClient) {
                    this.clientHasIntegration()
                }

                this.$root.$emit('bv::show::modal', modalName)
            }
        },
        getConsumptionDetails() {
            if (this.isClient) {
                api.stats.getConsumptionDataService()
                    .then(response => {
                        this.consumption_details = response.data;
                        this.remaining = response.data.remaining;
                        this.currentPlan = response.data.plan;

                        if(typeof response.data.plan.service_id !== 'undefined') {
                            this.AccountServicePlans[response.data.plan.service_id] = response.data;
                            this.$store.dispatch('Auth/updateAccountServicePlans', this.AccountServicePlans);
                        }
                    })
            }
        },
        clientHasIntegration() {
            let queryParams = {
                page: 1,
                per_page: 1,
                account: this.user ? this.user.account_id : null
            }
            let popUpCreateInt = null;

            return api.integrations.list(queryParams)
                .then((response) => {
                    if (!response.data.data.length) {
                        popUpCreateInt = true
                    }
                })
                .finally(() => {

                    if (popUpCreateInt) {
                        helper.popUpCreateIntegration(this)
                    }

                })
        },
        onShowInvoiceHistories(item) {
             this.integrationLogs = item.integration_logs;
            this.integration_id = item.id;
            setTimeout(() => {
                this.$root.$emit('bv::show::modal', 'modal-timeline')
            }, 400);
        },
        onFilter(form) {
           this.$refs['dt-integrations'].filterTable(form);
        },
        finishedFilter() {
             this.$refs['integrations-filters'].finishFilter();
        },
        toggleInputsFilter(event) {
            const parentElement = event.target.parentElement;
            if (this.isFilter) $(parentElement).parents('.row').removeClass('alert-secondary');
            else $(parentElement).parents('.row').addClass('alert-secondary');
            this.isFilter = !this.isFilter
        },
        initDates() {

            var date = helper.dateToYMD(new Date());
            var minDate = helper.dateToYMD(helper.AddOrSubractDays(new Date(), 6, false));

            this.dates.to = date;
            this.dates.from = minDate;
            this.dates.max = date;
        },
        graph(item) {
            this.entity = item;
            this.showChart = false;
            let options = {
                "integration_id": item.id,
                "from": this.dates.from,
                "to": this.dates.to,
            }
            api.stats.dripWarmingIntegrationStats(options)
                .then(response => {
                    const data = response.data;
                    var responseData = data.chart;
                    const ONE_DAY = 1;
                    var chars = {
                        title: item.tag_name + ' (Events Sent ' + options['from'] + ' to ' + options['to'] + ')',
                        type: 'line',
                        colors: ['#36A2EB'],
                        xKeys: 'period',
                        yKeys: ['a'],
                        labels: ['Events'],
                    }
                    let stat = false;
                    let dates = data.dates;
                    if (dates.length == ONE_DAY) {
                        let _dates = dates;
                        this.xLabels = "hour";
                        stat = true;
                        dates = [];
                        for (let i = 0; i < 24; i++) {
                            let date = _dates[0];
                            dates.push(this.getDateAndTime(date, i));

                        }
                    } else {
                        this.xLabels = "day";
                    }
                    this.$root.$emit('chartDone', item);
                    chars.bodyData = this.chartData(dates, responseData, stat);
                    setTimeout(() => {
                        chars.xLabels = this.xLabels;
                        this.charts = chars;
                        this.showChart = true;
                    }, item, this, 1000);

                    setTimeout(() => {
                        item.editable = false;
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".charts").offset().top
                        }, 800)
                    }, item, 1000);
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.progress = false;
                })

        },
        adminChartEventBuildSeries(keys = [], labels = [], statistics = []) {

            let series = [];
            let serie = {};
            let data = [];

            for (let index = 0; index < labels.length; index++) {
                serie.name = labels[index];
                if (statistics.length > 0) {
                    for (let i = 0; i < statistics.length; i++) {
                        data.push(statistics[i][keys[index]]);

                    }
                }
                serie.data = data;
                series.push(serie);
                serie = {};
                data = [];
            }

            return series;
        },
        adminGraph(item) {
            this.entity = item;
            this.showChart = false;
            this.loadAdminStats = false;
            this.apexChartTitle = item.name;
            this.params = {
                title: item.name,
                from: this.dates.from,
                to: this.dates.to,
                integration_id: item.id,
                account_id: item.account_id
            }
            api.stats.dripWarmingIntegrationAdminStats(this.params)
                .then(response => {
                    let keys = response.data.keys;
                    let labels = response.data.labels;
                    let statistics = response.data.chart;
                    let dates = _.map(statistics, function (o) {
                        return o.date
                    });
                    this.adminEventsChart.categories = dates;
                    this.adminEventsChart.series = this.adminChartEventBuildSeries(keys, labels, statistics);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loadAdminStats = true;
                    this.$root.$emit('chartDone', this.item);
                    setTimeout(() => {
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".adminCharts").offset().top
                        }, 800)
                    }, 1000);
                })
        },
        chartData(dates, responseData, ONE_DAY) {
            var bodies = [];
            let responseDatas = responseData;
            for (let index = 0; index < dates.length; index++) {
                var series = {
                    a: 0,
                    period: dates[index]
                }
                if (responseDatas.length > 0) {
                    responseDatas.forEach(data => {
                        if (ONE_DAY) {
                            if (data.hour == index) {
                                series.period = dates[index]
                                series.a = data.events

                            }
                        } else {
                            if (data.period == dates[index]) {
                                series.period = data.period
                                series.a = data.events
                            }
                        }
                    });

                }
                bodies.push(series)
            }

            return bodies;
        },
        getDateAndTime(date, i) {
            return date + ' ' + (i.toString().length > 1 ? i.toString() : '0' + i.toString()) + ':00:00';
        },
        onSubmit() {
            this.submitload = true;
            this.loadAdminStats = false;
            this.showChart = false;
            let date = {
                from: this.dates.from,
                to: this.dates.to
            }
            this.$refs['dt-integrations'].setDates(date)
        },
        filterIntegrationColum(cols, selected, defaults) {
            this.integrationColumns.options = cols;
            this.integrationColumns.selected = selected;
            this.integrationColumns.defaults = defaults;
        },
        SelectedColumns(cols) {
            this.$refs['dt-integrations'].onSelect(cols);
        },
        hasRoles(roles = []) {
            return !_.isEmpty(_.filter(this.user.roles, function (r) {
                return roles.includes(r.slug);
            }))
        },
    },
    computed: {
        ...mapGetters({
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            UserSubscriptions: 'Auth/UserSubscriptions',
            AccountServicePlans: 'Auth/AccountServicePlans',
            platforms_co: 'platformConfigs'
        })
    },
    data() {
        return {
            isFilterLists: false,
            isFilter: false,
            admin: {
                account: {
                    code: null,
                    name: ''
                }
            },
            dates: {
                to: null,
                from: null,
            },
            isAdmin: IS_MANAGER,
            btnAddIsDisabled: !IS_MANAGER ? false : true,
            renderModalAdd: false,

            parentcomponent: this,
            loader: false,
            remaining: null,
            consumption_details: null,
            // For auth2 btn
            auth2: {
                addNew: true,
                btnAuthorize: false,
                inProgress: false,
                state: '',
                btnLabel: '',
                listAccounts: [],
                platforms: [],
                platform: null,
                data: null
            },
            integration_id: null,
            currentPlan: null,
            currentSubscription: null,
            popUpCreateInt: false,
            loadAdminStats: false,
            showChart: false,
            apexChartTitle: '***',
            charts: {},
            params: {},
            adminEventsChart: {
                series: [],
                categories: []
            },
            apexColumnsHide: ['Others', 'Contact Clean', 'Time Limit Reached', 'Daily Limit Reached', 'Contact Already Tagged', 'Events Already Sent', 'Insufficient Balance'],
            entity: {},
            submitload: false,
            integrationColumns: {
                options: [],
                selected: [],
                defaults: []
            },
            is_manager: false
        };
    },
};
</script>

<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

#edit-modal___BV_modal_body_ {
    background-color: #f3f7fd;
}
#label-btn{
    visibility:hidden;
}
</style>
