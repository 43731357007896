<template>
  <iq-card class="p-2">
    <template v-slot:headerTitle>
      <h4 class="card-title">
        <i class=""></i> Platform Settings
      </h4>
    </template>

    <template  v-slot:body>
      <input type="text" class="form-control my-2" v-model="filterConfigValue" placeholder="search">
      <div class="row p-2 pb-0">
        <div class="col-7">Platform</div>
        <div class="col-1">Active</div>
        <div class="col-2">Collect Contact Details</div>
        <div class="col-1">LiveLeads</div>
        <div class="col-1">LiveOpens</div>
      </div>
      <hr>
      <div class="platform-config-container p-2 pt-0" style="margin-top: -15px">

        <div v-for="config in FilterplatformConfigs"  :key="config.id" class="row platform-config-row p-2" style="justify-content: space-between;">
          <div class="col-7" style="align-items: center;align-self: center;">

           <p class="m-0 py-2"> {{ config.name }}</p>

          </div>
          <div class="col-1" style="align-self: center;justify-self: center;justify-items: center;text-align: center;">
            <b-form-checkbox switch v-model="config.active" @change="updatePlatformConfig(config)"  size="lg" button-variant="primary"></b-form-checkbox>
          </div>
          <div class="col-2" style="align-self: center;justify-self: center;justify-items: center;text-align: center;">
            <b-form-checkbox switch v-model="config.collect_contact_details" @change="updatePlatformConfig(config)"  size="lg" button-variant="primary"></b-form-checkbox>
          </div>
          <div class="col-1" style="align-self: center;justify-self: center;justify-items: center;text-align: center;">
            <b-form-checkbox switch v-model="config.ll_service" @change="updatePlatformConfig(config)"  size="lg" button-variant="primary"></b-form-checkbox>
          </div>
          <div class="col-1" style="align-self: center;justify-self: center;justify-items: center;text-align: center;">
            <b-form-checkbox class="mx-auto" switch v-model="config.lo_service" @change="updatePlatformConfig(config)"  size="lg" button-variant="primary"></b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
  </iq-card>
</template>


<script>

import api from "@/api";

export default {
  name: "PlatformConfigurationSettings",
  props: {},
  data(){
    return {
      platformConfigs : [],
      filterConfigValue : null,
    }
  },
  mounted(){

    api.settings.getPlatformConfigs().then(response => {

      this.platformConfigs = response.data.map(config=>{
          return {
            'id' : config.id,
            'name' : config.name ,
            'active'  : config.active === 1,
            'collect_contact_details' : config.collect_contact_details === 1,
            'll_service' : config.ll_service === 1,
            'lo_service' : config.lo_service === 1
          }
      });

    })
  },
  methods : {
    updatePlatformConfig(config){
      api.settings.updatePlatformConfigs(config.id,
          {
            active : config.active,
            ll_service:config.ll_service,
            lo_service:config.lo_service,
            collect_contact_details: config.collect_contact_details
          }, ).then((response)=>{

        this.$bvToast.toast(response.message, {
          variant : 'success',
          title : 'Updated'
        });

        api.settings.getPlatformConfigs().then(response => {
          this.$store.dispatch('updatePlatformConfigs', response.data);

        }).catch((error)=>{
          console.log(error);
        });

      });
    }
  },
  computed : {
    FilterplatformConfigs(){
      return this.platformConfigs.filter(config =>{

        if(!this.filterConfigValue) return true;

        return config.name.toLowerCase().includes(this.filterConfigValue.toLowerCase());
      })
    }
  }


}


</script>


<style scoped>
.platform-config-container {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.platform-config-row:hover {
  background: #92a7cf17 !important;
  border-radius: 0.5rem;
}
</style>