import {SERVICES} from "@/constantes";

export default {
  loggedIn: state => state.loggedIn,
  token: state => state.token,
  user: state => state.user,
  isSubscribed: state => state.isSubscribed,
  isAdmin: state => state.isAdmin,
  isClient: state => state.isClient,
  isAccounting: state => state.isAccounting,
  isAccountManager: state => state.isAccountManager,
  isManager: state => state.isManager,
  tokenAs: state => state.tokenAs,
  userAdmin: state => state.userAdmin,
  liveOpenersSubscribed: state => state.liveOpenersSubscribed,
  dripWarmingSubscribed: state => state.dripWarmingSubscribed,
  liveLeadsSubscribed: state => state.liveLeadsSubscribed,
  liveValidationSubscribed: state => state.liveValidationSubscribed,
  livePlans: state => state.livePlans,
  lastSubscriptions: state => state.lastSubscriptions,
  UserSubscriptions: state => state.UserSubscriptions,
  UserApiToken: state =>state.api_token,
  AccountServicePlans: state => state.AccountServicePlans,
  hasOption: (state) => (service,option) => {



    if(state.isAdmin) {
      if(state.CurrentAccountOptions[service]){
        return state.CurrentAccountOptions[service][option] ?? false;
      }


    }


    let options = null;

    if(state.UserSubscriptions[service]){

      options = state.UserSubscriptions[service].options;

      options = JSON.parse(options);
    }




    if(!options) return false;



    return options[option] === true;
  },
  isOwnerAccount(state) {
    return state.user.account && state.user.account.user_id === state.user.id;
  },
  UserMetaData: state => state.UserMetaData,
  supportChatRoomID: state => state.supportChatRoomID
}
