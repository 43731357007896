<template>

<div>

  <ApexChart v-if="showChart"
      :element="'chart-id'"
      :chartOption="chartOptions"
      :isLive="false"
      :columnsHide="[]"
  ></ApexChart>


</div>


</template>

<script>

import ApexChart from "@/components/sofbox/charts/ApexChart.vue"
import api from "../../../api";

export default {
  name:"VerifiedByDateState",
  components: {
    ApexChart
  },
  mounted(){

    this.chartOptions.xaxis.categories =  Array.from({ length: 30 }, (_, i) => {
      const d = new Date();
      d.setDate(d.getDate() - (29 - i)); // Generate last 30 days
      return d.toISOString().split('T')[0]; // Format YYYY-MM-DD
    })

    this.fetchDailyStats();
  },
  data() {
    return {
      showChart:false,
      chartOptions: {
        colors: ['#0084ff', '#00ca00', '#008000', '#008080', '#FF00FF', '#800080', '#FFFF00', '#00FFFF', '#800000'],
        chart: {
          type: 'area',
          height: 500,
        },
        stroke: {
          curve: 'smooth', // Makes the line smooth
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          labels: {
            useSeriesColors: true
          },
          markers: {
            customHTML: [
              function () {
                return ''
              },
              function () {
                return ''
              },
              function () {
                return ''
              }
            ]
          }

        },
        series: [],
        xaxis: {
          type: 'category', // Ensure x-axis matches the date labels
          categories: []
        },
        yaxis: {
          forceNiceScale: true,
        },
      }
    }
  },
  methods:{
    fetchDailyStats(){
      api.default.get('live-validation/daily-stats').then((response)=>{

        const last30Days = Array.from({ length: 30 }, (_, i) => {
          const d = new Date();
          d.setDate(d.getDate() - (29 - i));
          return d.toISOString().split('T')[0];
        });

        this.$set(this.chartOptions.xaxis, 'categories', last30Days);
        this.$set(this.chartOptions, 'series', this.formatData(response.data));
        this.$set(this.chartOptions, 'colors', this.generateFlatColors(this.chartOptions.series.length));
        this.showChart = true;
      }).catch((error)=>{
        console.log('error=>',error)
      })
    },
    formatData(data) {
      const today = new Date();
      const last30Days = Array.from({ length: 30 }, (_, i) => {
        const d = new Date();
        d.setDate(today.getDate() - (29 - i)); // Generate last 30 days
        return d.toISOString().split('T')[0]; // Format YYYY-MM-DD
      });

      return data.map(group => {
        const mappedData = last30Days.map(date => {
          const found = group.data.find(d => d.date === date);
          return { x: date, y: found ? Number(found.total) : 0 };
        });
        return { name: this.getCodeName(group.code), data: mappedData };
      });
    },
    generateFlatColors(count) {
      const colors = [];
      const goldenRatio = 0.618033988749895;
      let hue = Math.random();

      for (let i = 0; i < count; i++) {
        hue += goldenRatio;
        hue %= 1;
        colors.push(this.hslToHex(Math.round(hue * 360), 70, 50)); // Pass values separately
      }

      return colors;
    },
    hslToHex(h, s, l) {
      s /= 100;
      l /= 100;

      const c = (1 - Math.abs(2 * l - 1)) * s;
      const x = c * (1 - Math.abs((h / 60) % 2 - 1));
      const m = l - c / 2;
      let r = 0, g = 0, b = 0;

      if (h < 60) [r, g, b] = [c, x, 0];
      else if (h < 120) [r, g, b] = [x, c, 0];
      else if (h < 180) [r, g, b] = [0, c, x];
      else if (h < 240) [r, g, b] = [0, x, c];
      else if (h < 300) [r, g, b] = [x, 0, c];
      else [r, g, b] = [c, 0, x];

      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);

      return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
    },
    getCodeName(code){
      const codeMap = {
        [-1]: "Not Cleaned",
        [-2]: "Not Cleaned",
        [0]: "Retry",
        [1]: "Verified",
        [2]: "Undeliverable",
        [3]: "Catch All",
        [4]: "Role",
        [5]: "Malformed",
        [6]: "Spam Trap",
        [7]: "Complainer",
        [9]: "Bot",
        [10]: "Seed Account",
        [11]: "Unknown",
        [12]: "Unauthorized",
        [13]: "Disposable",
        [20]: "Suppressed"
      };

      return codeMap[code] || "Unknown Code";
    }
  }
}
</script>