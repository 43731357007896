<template>
<b-container fluid>
  <div>
    <LiveValidationStats></LiveValidationStats>
  </div>


  <div>
    <iq-card class="p-4">
      <VerifiedByDateState></VerifiedByDateState>
    </iq-card>
  </div>
</b-container>
</template>

<script>

import {
  sofbox
} from "@/config/pluginInit";

import LiveValidationStats from "./stats/LiveValidationStatas.vue";
import VerifiedByDateState from "./stats/verifiedByDateStats.vue";

export default {
  name: "live_validation_stats",
  components: {
    LiveValidationStats,
    VerifiedByDateState
  },
  mounted() {
    sofbox.index();
  },
  data() {
    return {}
  },
  methods : {

  }
}
</script>