
<template>
<div class="card p-4 my-2 mb-4">


  <div class="row">

    <div class="col">
      <p class="text-center text-muted  m-0">Free</p>
      <h2 class="text-center text-muted" >{{ current_free_accounts.accounts ?? 0 }} </h2>
    </div>

    <div v-for="subscription in current_paid_accounts"  class="col">
      <p class="text-center text-muted  m-0">{{subscription.plan}}</p>
      <h2 class="text-center text-muted" >{{ subscription.accounts}}</h2>
    </div>
  </div>

  <ApexChart v-if="!loading"
      element="account-per-subscription-chart"
      :chartOption="chartOptions"
      :isLive="false"
      :columnsHide="[]"
  />
</div>
</template>

<script>



import api from "../../../api";

export  default {
  name: 'AccountPerSubscriptionChart',
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  components: {
  },
  data(){
     return {
       current_paid_accounts:[],
       current_free_accounts:[],
       loading:true,
      chartOptions: {
        chart: {
          height: 350,
          stacked: false,
          type: 'area' // Choose the chart type (line, bar, etc.)
        },
        series: [],
        xaxis: {
          categories: []
        }
      }
    };

  },
  mounted() {
    this.fetchData();
  },
  methods : {

    fetchData(){
      this.loading = true;
        api.default.get('dashboard/admin/stats/accounts/1').then((response)=>{
          let data = response.data;

          this.chartOptions.xaxis.categories = data.date;
          this.chartOptions.series    = data.account_by_subscriptions;
          this.current_paid_accounts  = data.current_paid_accounts;
          this.current_free_accounts  = data.current_free_accounts;

          this.loading = false;

        });
    }


  }
}

</script>


<style scoped>

</style>