export default function (axios) {
    return {
        list: (params = {}) => {
            return axios.get(`/drip-warming/lists`, {params})
                .then(response => response.data);
        },
        get: (listId) => {
            return axios.get(`drip-warming/lists/${listId}`)
                .then(response => response.data);
        },
        put: (listId, listData) => {
            return axios.put(`drip-warming/lists/${listId}`, listData)
                .then(response => response.data)
        },
        delete: (listId) => {
            return axios.delete(`drip-warming/lists/${listId}`)
                .then(response => response.data)
        },
        eiInit: (listId) => {
            return axios.post(`drip-warming/lists/ei-init/${listId}`)
                .then(response => response.data)
        },
        logs: (listId) => {
            return axios.get(`drip-warming/lists/${listId}/logs`)
                .then(response => response.data);
        },


        updateAutoRefresh(listId,days)
        {
            return axios.put(`drip-warming/lists/${listId}/auto-refresh`,{ "refreshDays": days })
                .then(response => response.data);
        },

    }
}
