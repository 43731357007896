<template>
<b-container fluid>



    <iq-card>

      <template v-slot:headerAction>
        <div v-if="admin" class="mx-3">
          <AccountsMultiselect @onSelect="onSelectAccount" />
        </div>
        <b-button variant="primary" :disabled="is_disable_btn" v-b-modal.modal-import-contacts>Add New</b-button>
      </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <DtContacts :organization="account" ref="dt-contacts"/>
                </b-col>
            </b-row>
        </template>
    </iq-card>
    <b-modal  size="lg" id="modal-import-contacts" ref="modal-import-contacts" title="Import Contacts" hide-footer>
        <p>Please find <a href="#" class="text-primary mb-3" v-show="!is_loading_sample" @click.prevent="sampleCsv">here</a> <b-spinner v-show="is_loading_sample" small label="Small Spinner" type="grow"></b-spinner> an example file</p>
        <Import @onSubmit="importContacts" :imported="imported" :account_id="account.code"/>
    </b-modal>
</b-container>
</template>

<script>
import {
    sofbox
} from "@/config/pluginInit";

import Import from "./children/import.vue";
import DtContacts from "./children/table.vue";
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import {
    mapGetters
} from "vuex";
import api from '@/api/RestClient'
import IqCard from "../../components/sofbox/cards/iq-card.vue";
import LiveValidationStats from "./stats/LiveValidationStatas.vue";

export default {
    name: "live_validation_integration",
    components: {
      LiveValidationStats,
      IqCard,
        Import,
        DtContacts,
        AccountsMultiselect
    },
    data() {
        return {
            account: {},
            imported: false,
            is_disable_btn: false,
            is_loading_sample: false,
            uri: "uploads/live-validation/sample.csv"
        }
    },
    mounted() {
        sofbox.index();
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            client: 'Auth/isClient',
        })
    },
    methods: {
        importContacts(form) {
            return api.emailValidations.import(form)
                .then((response) => {
                    this.$root.$emit('bv::refresh::table', "dt-contacts")
                    this.$bvToast.toast(response.message, {
                        title: 'Import',
                        variant: 'success'
                    });
                })
                .catch((error) => {
                    let errors = error.response.data.errors;
                    if (errors.hasOwnProperty('file')) {
                        this.$bvToast.toast(errors['file'], {
                            title: 'File error',
                            variant: 'danger'
                        });
                    }

                    if (errors.hasOwnProperty('list_name')) {
                        this.$bvToast.toast(errors['list_name'], {
                            title: 'List name error',
                            variant: 'danger'
                        });
                    }

                    if (errors.hasOwnProperty('account_id')) {
                        this.$bvToast.toast(errors['account_id'], {
                            title: 'Account error',
                            variant: 'danger'
                        });
                    }

                    if ('message' in error.response.data) {
                         this.$bvToast.toast(error.response.data.message, {
                            title: 'Ooops ...',
                            variant: 'danger'
                        });
                    }
                })
                .finally(() => {
                    this.imported = true;
                    this.$refs['modal-import-contacts'].hide()
                })
        },
        onSelectAccount(account) {
            this.is_disable_btn = false;
            this.account = account;
        },
        sampleCsv() {
            let url = "validations-emails/sample";
            this.is_loading_sample = true;
            return api.emailValidations.downloadSampleCsv(url).then(response => {
                    this.$bvToast.toast('Csv sample downloaded successfully', {
                        title: 'Success',
                        variant: 'success'
                    });

                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement('a');
                var fileName = 'live-validation-sample-' + Date.now() + '.csv';

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                })
                .finally(() => { 
                    this.is_loading_sample = false;
                })
                .catch(error => {
                    this.$bvToast.toast('Something want wrong', {
                        title: 'Warning',
                        variant: 'error'
                    });
                })
            
        }
    }
}
</script>

