<template>
    <b-container fluid>
        
      <b-modal
          id="add-integration-wizard-modal"
          ref="add-integration-wizard-modal"
          title="" size="xl"
          @hide="resetAddModal"
          centered hide-footer
          :no-close-on-backdrop="true">

        <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <div class="d-flex">
            <h5>New integration</h5>
            <span class="ml-1" v-b-tooltip.hover title="Integration help guide"><a :href="help.link" target="_blank"><i
                    class="fa fa-info-circle text-info fa-lg"></i></a></span>
            </div>
            <div @click="hiddenModalIntegration">
            <i class="fa fa-close text-danger cursor-pointer fa-lg"></i>
            </div>
        </template>

        <!-- Integration Wizard Step Navigation   -->

        <AddIntegrationWizardModalNav
            :steps="steps"
            :current_step_index="current_step_index">
        </AddIntegrationWizardModalNav>

        <!-- End - Integration Wizard Step Navigation   -->


        <b-row>
          <b-col lg="12" sm="12" class="p-4">
              <div v-if="currentStep">
                <component
                    ref="StepComponent"
                    :account="account"
                    :is="currentStep.component"
                    :form="form"
                    :lists="lists"
                    :tags="tags"
                    :platform="platform"
                    :loadingProp="loading"
                    :PlatformHelper="platformHelper"
                    @back="handelBackEvent"
                    @cancel="handelCancelEvent"
                    @next="nextStep"
                    @finish="finishWizard"
                    @preloadLists="handlePreloadListsEvent"
                    @listCreated="handleListCreatedEvent"
                    @tagAdded="handleTagAdded"

                />
              </div>
  
          </b-col>
        </b-row>
      </b-modal>
    </b-container>
  </template>
  
  
  <script>
  import platforms from '@/helpers/platforms/new';
  import PlatformLoCreateIntegrationStep    from './steps/PlatformLoCreateIntegrationStep.vue';
  import ListLoCreateIntegrationStep        from './steps/ListLoCreateIntegrationStep.vue';
  import IntegrationLoCreateIntegrationStep from './steps/IntegrationLoCreateIntegrationStep.vue';
  import FinishLoCreateIntegrationStep      from './steps/FinishLoCreateIntegrationStep.vue';
  import AddIntegrationWizardModalNav       from './AddIntegrationWizardModalNav.vue';

  import api from "@/api";
  
  export default {
    name:'AddIntegrationWizardModal',
    props:['account'],
    components:{
      PlatformLoCreateIntegrationStep,
      ListLoCreateIntegrationStep,
      IntegrationLoCreateIntegrationStep,
      FinishLoCreateIntegrationStep,
      AddIntegrationWizardModalNav
    },
    data(){
      return{
        platformHelper:null,
        current_step_index:0,
        platform:null,
        form:{},
        lists:[],
        tags:[],
        loading:false,
        steps:[
            {
                index: 0,
                title: 'Platform',
                name: 'platform',
                component: PlatformLoCreateIntegrationStep.name,
                icon: 'ri-apps-line'
            },
            {
                index: 1,
                title: 'List',
                name: 'list',
                component: ListLoCreateIntegrationStep.name,
                icon: 'ri-file-list-line'
            },
            {
                index: 2,
                title: 'Integration',
                name: 'integration',
                component: IntegrationLoCreateIntegrationStep.name,
                icon: 'ri-menu-add-line'
            },
            {
                index: 3,
                title: 'Finish',
                name: 'finish',
                component: FinishLoCreateIntegrationStep.name,
                icon: 'ri-check-double-line'
            }
        ],
        help: {
            'platform_name': null,
            'base_url': 'https://help.inboxgeek.com/',
            'link': 'https://help.inboxgeek.com/lo-integration-guides/',
            'hrefs': [
                { 'name': 'maropost', 'platform_uri': 'docs-category/maropost/', 'list_uri': 'docs/get-list-id-3/', 'integration_uri': 'docs/integration-2-2/' },
                { 'name': 'activecampaign', 'platform_uri': 'docs-category/activecampaign-integration/', 'list_uri': 'docs/list-2/', 'integration_uri': 'docs/integration-4/' },

                { 'name': 'campaigner', 'platform_uri': 'docs-category/campaigner-integration/', 'list_uri': 'docs/list-6/', 'integration_uri': 'docs/integration-6/' },
                { 'name': 'ontraport', 'platform_uri': 'docs-category/ontraport-integration/', 'list_uri': 'docs/list-4/', 'integration_uri': 'docs/integration-3/' },

                { 'name': 'klaviyo', 'platform_uri': 'docs-category/klaviyo/', 'list_uri': 'docs/get-list-id-2/', 'integration_uri': 'docs/set-up-your-api-key/' },
                { 'name': 'convertkit', 'platform_uri': 'docs-category/convertkit-integration/', 'list_uri': 'docs/list-3/', 'integration_uri': 'docs/integration-5/' },

                { 'name': 'brevo', 'platform_uri': 'docs-category/brevo-integration/', 'list_uri': 'docs/list-15/', 'integration_uri': 'docs/integration-15/' },
                { 'name': 'inbox', 'platform_uri': 'docs-category/inbox-integration/', 'list_uri': 'docs/list-16/', 'integration_uri': 'docs/integration-16/' },

                { 'name': 'inboxsuite', 'platform_uri': 'docs-category/maropost/', 'list_uri': 'docs/get-list-id-3/', 'integration_uri': 'docs/integration-2-2/' },
                { 'name': 'emailoctopus', 'platform_uri': 'emailoctopus-integration/', 'list_uri': 'docs/list-14/', 'integration_uri': 'docs/integration-14/' },

                { 'name': 'aweber', 'platform_uri': 'docs-category/aweber-integration/', 'list_uri': 'docs/list-5/', 'integration_uri': 'docs/integration-2/' },
                { 'name': 'hubspot', 'platform_uri': 'docs-category/hubspot-integration/', 'list_uri': 'docs/list-9/', 'integration_uri': 'docs/integration-9/' },

                { 'name': 'mailchimp', 'platform_uri': 'docs-category/mailchimp-integration/', 'list_uri': '/docs/list-11/', 'integration_uri': 'docs/integration-11/' },
                { 'name': 'keap', 'platform_uri': 'docs-category/keap-integration/', 'list_uri': 'docs/list-8/', 'integration_uri': 'docs/integration-8/' },

                { 'name': 'gohighlevel', 'platform_uri': 'docs-category/highlevel-integration/', 'list_uri': 'docs/list-18/', 'integration_uri': 'docs/integration-18/' },
                { 'name': 'listrak', 'platform_uri': 'docs-category/listrak-integration/', 'list_uri': 'docs/list-19/', 'integration_uri': 'docs/integration-19/' },

                { 'name': 'sendgrid', 'platform_uri': 'docs-category/sendgrid-integration', 'list_uri': 'docs/list-17/', 'integration_uri': 'docs/integration-17' },
                { 'name': 'omnisend', 'platform_uri': '/docs-category/omnisend-integration/', 'list_uri': 'docs/omnisend-list/', 'integration_uri': 'docs/omnisend-integration/' },

                { 'name': 'ongage', 'platform_uri': 'docs-category/ongage-integration/', 'list_uri': 'docs/list-ongage/', 'integration_uri': 'docs/integration-ongage/' },
                { 'name': 'blastable', 'platform_uri': 'docs-category/blastable-integration/', 'list_uri': 'docs/list-7/', 'integration_uri': 'docs/integration-7/' },

                { 'name': 'sendrapp', 'platform_uri': 'docs-category/activecampaign-integration/', 'list_uri': 'docs/list-2/', 'integration_uri': 'docs/integration-4/' },
                { 'name': 'beehiiv', 'platform_uri': 'docs-category/beehiiv-integration/', 'list_uri': 'docs/list-beehiiv/', 'integration_uri': 'docs/integration-beehiiv/' },

                { 'name': 'getresponse', 'platform_uri': 'docs-category/getresponse-integration/', 'list_uri': 'docs/list-10/', 'integration_uri': 'docs/integration-10/' },
                { 'name': 'expertsender', 'platform_uri': 'docs-category/expertsender-integration/', 'list_uri': 'docs/list-12/', 'integration_uri': 'docs/integration-12/' },

                { 'name': 'sendlane', 'platform_uri': 'docs-category/sendlane/', 'list_uri': 'docs/list/', 'integration_uri': 'docs/integration/' },
                { 'name': 'mailsend', 'platform_uri': 'docs-category/mailsend-integration/', 'list_uri': 'docs/mailsend-list/', 'integration_uri': 'docs/mailsend-integration/' },

            ]
        }
      }
    },
    methods:{
  
        nextStep(data) {

            // save emitted data into the form
            let current = this.current_step_index;
            console.log('Step : ' + current);
            if(current === 0) {
                this.getHelpLinkResource('list');
            } else if (current === 1) {
                this.getHelpLinkResource('integration');
            }
            if (data) {
                this.form[this.steps[this.current_step_index].name] = data;
            }

            this.current_step_index++;

        },
        handelBackEvent() {

            // remove current step data if exist:

            if (this.form[this.steps[this.current_step_index].name]) {
                delete this.form[this.steps[this.current_step_index].name];
            }

            this.current_step_index--;
            let step = this.current_step_index;

            if (step === 0) {
                this.getHelpLinkResource('platform')
            } else if (step === 1) {
                this.getHelpLinkResource('list');
            } else if (step === 2) {
                this.getHelpLinkResource('integration');
            } else {
                this.getHelpLinkResource()
            }
        },
        handlePreloadListsEvent(platform) {

          this.getHelpLinkResource('platform');

          let validApiKey = false;
          this.loading    = true;

          this.platform = platform;

          let platformHelper = platforms.get({

            id    : platform.id,
            name  : platform.name.toLowerCase(),

          });

          if(platformHelper) {

            this.platformHelper = platformHelper;
            // Fetch reception lists :

            platformHelper
                .fetchIntegrationReception()
                .then((response)=>{

                  if(response.success){

                    this.lists = response.data;

                  }

                  this.loading = false;
                  validApiKey   = true;

                }).catch(( error )=>{

                })

                .finally(()=>{

                  if(platformHelper.hasTrigger){

                    // Fetch Integration Triggers Tags/events/lists :
                    platformHelper
                        .fetchIntegrationTriggers()
                        .then((response)=>{

                          if(response.success){

                            this.tags = response.data;

                          }
                        }).catch(( error )=>{

                    })

                        .finally(() => {

                          this.$root.$emit('valid-api-key', {state: validApiKey, hasLists: this.lists.length > 0});

                        });

                  }else{

                    this.$root.$emit('valid-api-key', {state: validApiKey, hasLists: this.lists.length > 0});

                  }

                });
          }

        },


        handleListCreatedEvent(list) {
            this.$emit('ListCreated', list);
        },
        handleTagAdded(tag) {
            this.tags.push(tag);
        },
        finishWizard() {
            
            // Format integration data and send request to store item
            this.loading = true;
            
            let formData = this.integrationFormData();

            api.dripWarming
                .integrations
                .post(formData)
                .then((response) => {

                    // after user confirms data form , send create request, returns response and close the modal.
                    this.loading = false;
                    this.$swal('success', 'Integration successfully created', 'success');
                    this.$root.$emit('integration-created', response.data);
                    // this.handelCancelEvent();

                  this.$emit('integrationCreated');

                })
                .catch((error) => {
                    this.loading = false;
                    let message = 'Something went wrong!';

                    if (error.response && error.response.status !== 500) {
                        message = error.response.data.message
                    }

                    this.$swal('error', message, 'error');

                });

        },
        handelCancelEvent() {
            this.form = {};
            this.$refs['add-integration-wizard-modal'].hide();
            this.current_step_index = 0;

        },
        resetAddModal(test) {
            this.$emit('hide', test)
        },
        hiddenModalIntegration(e) {
            var swalObj = {
                title: 'Are you sure?',
                html: 'You are about to leave the integration setup form. Any unsaved changes will be lost.\n' +
                    '\n' +
                    'click<a href="https://help.inboxgeek.com/lo-integration-guides/" target="_blank">here</a> if you need help completing your integration setup.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel!',
                showLoaderOnConfirm: true
            };
            this.$swal(swalObj).then((result) => {

                if (result.value) {
                    this.$refs['add-integration-wizard-modal'].hide()
                }

            });
        },
        integrationFormData() {
            let integration = {
                name: this.form.integration.name,
                tag_id: this.form.integration.tag.code,
                tag_name: this.form.integration.tag.name,
                recurrence: this.form.integration.recurrence,
                limit: this.form.integration.limit,
                time_limit: {
                  time_limit_from: this.form.integration?.time_limit?.time_limit_from ?? null,
                  time_limit_to:  this.form.integration?.time_limit?.time_limit_from ?? null
                }
            }
            // resume
            let dataIntegration = {
                organizationId: this.account,
                integration: integration,
                list: this.form.list.list,
                platform: this.form.platform && this.form.platform.platform ? this.form.platform.platform.id : null,
                field:  null,
                // field: comp.form.field ?? null
            }

            var bodyFormData = new FormData();
            bodyFormData.append('data', JSON.stringify(dataIntegration));
            return bodyFormData;
        },
        getHelpLinkResource(resource = null) {

            this.help.link = 'https://help.inboxgeek.com/lo-integration-guides/';
            const the_platform_name = this.help.platform_name;
            console.log(the_platform_name);
            if (the_platform_name) {
                let data = _.find(this.help.hrefs, function (q) { return q.name == the_platform_name; })
                if (data) {
                    const item = `${resource}_uri`
                    this.help.link = this.help.base_url + data[item]//eric
                }
            }
        },
    },
    computed:{
      currentStep(){
        let current_step = null;
        this.steps
            .forEach((step)=>{
              if(this.current_step_index === step.index){
                current_step = step;
              }
            });
  
        return current_step;
      }
    },
    watch: {
        form:{
            handler(newValue, oldValue) { 
                console.log('success');
            }
        }
    }
  }
  
  </script>