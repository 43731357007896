// Import class dependencies.
import axios from 'axios'
import _default from './endpoints/_default'
import discounts from './endpoints/discounts'
import referrals from './endpoints/referrals'
import commissions from './endpoints/commissions'
import invoiceLogs from './endpoints/invoiceLogs'
import subscriptionsApi from './endpoints/subscriptions'
import plansApi from './endpoints/plans'
import balancesApi from './endpoints/balances'
import platformsApi from './endpoints/platforms'
import statsApi from './endpoints/stats'
import serviceApi from './endpoints/services'
import usersApi from './endpoints/users'
import profilesApi from './endpoints/profile'
import billingApi from './endpoints/billing'
import integrations from './endpoints/integrations'
import trackedLists from './endpoints/trackedLists'
import webHookPlatform from './endpoints/webHookPlatform'
import inboxSuitePlatform from './endpoints/inboxSuitePlatform'
import inboxgeekPlatform from './endpoints/inboxgeekPlatform'
import notifications from './endpoints/notifications'
import dashboardApi from './endpoints/dashboard'
import logsApi from './endpoints/logs'
import rolesApi from './endpoints/roles'
import accountsApi from './endpoints/accounts'
import invoicesApi from './endpoints/invoices'
import settings from './endpoints/settings'
import revenues from './endpoints/revenues'
import userMetadata from './endpoints/userMetadata'
import geSources from './endpoints/geSources'
import geRecords from './endpoints/geRecords'
import geAutomations from './endpoints/geAutomations'
import notificationTypes from './endpoints/notificationTypes'
import notificationEvents from './endpoints/notificationEvents'
import emailValidations from './endpoints/emailValidations'
import userSettings from './endpoints/userSettings'
import loAutomations from './endpoints/loAutomations'
import authApi from './endpoints/auth'
import suppressionList from './endpoints/suppressionList'
import loDailyLimitAutoUpdates from './endpoints/loDailyLimitAutoUpdates'
import supportTickets from './endpoints/supportTickets'
import supportChatQuestions from './endpoints/supportChatQuestions'
import dripWarming from './endpoints/dripWarming'

import store from "../store";
import router from '@/router'
import supportChat from "@/api/endpoints/supportChat";

// Default axios client.
let axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + '/api',
  timeout: 120000,
  headers: {
    Accept: 'application/json',
  },
});
// Response interceptor.
axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error && error.response && error.response.status === 401) {
    store.dispatch('Auth/resetUser', false).then(() => router.push({
      name: 'auth1.sign-in1'
    }))
  }
  return Promise.reject(error);
}
);

axiosInstance.interceptors.request.use((config) => {
  if (config.noAuth) {
    return config;
  };
  const token = store.getters['Auth/tokenAs'] ?? store.getters['Auth/token'];
  config.headers.Authorization = "Bearer " + token;
  return config
});

let api = {
  default: _default(axiosInstance),
  stats: statsApi(axiosInstance),
  auth: authApi(axiosInstance),
  users: usersApi(axiosInstance),
  balances: balancesApi(axiosInstance),
  dripWarming : dripWarming(axiosInstance),
  profiles: profilesApi(axiosInstance),
  billing: billingApi(axiosInstance),
  logs: logsApi(axiosInstance),
  roles: rolesApi(axiosInstance),
  accounts: accountsApi(axiosInstance),
  invoices: invoicesApi(axiosInstance),
  integrations: integrations(axiosInstance),
  trackedLists: trackedLists(axiosInstance),
  notifications: notifications(axiosInstance),
  dashboard: dashboardApi(axiosInstance),
  services: serviceApi(axiosInstance),
  platforms: platformsApi(axiosInstance),
  plans: plansApi(axiosInstance),
  discounts: discounts(axiosInstance),
  referrals: referrals(axiosInstance),
  commissions: commissions(axiosInstance),
  invoiceLogs: invoiceLogs(axiosInstance),
  subscriptions: subscriptionsApi(axiosInstance),
  webHookPlatform: webHookPlatform(axiosInstance),
  inboxSuitePlatform: inboxSuitePlatform(axiosInstance),
  inboxgeekPlatform: inboxgeekPlatform(axiosInstance),
  settings: settings(axiosInstance),
  revenues: revenues(axiosInstance),
  geSources: geSources(axiosInstance),
  geRecords: geRecords(axiosInstance),
  geAutomations: geAutomations(axiosInstance),
  userMetadata: userMetadata(axiosInstance),
  notificationTypes: notificationTypes(axiosInstance),
  notificationEvents: notificationEvents(axiosInstance),
  emailValidations: emailValidations(axiosInstance),
  userSettings: userSettings(axiosInstance),
  suppressionList:suppressionList(axiosInstance),
  loAutomations: loAutomations(axiosInstance),
  loDailyLimitAutoUpdates: loDailyLimitAutoUpdates(axiosInstance),
  supportTickets: supportTickets(axiosInstance),
  supportChats : supportChat(axiosInstance),
  supportChatQuestions: supportChatQuestions(axiosInstance),
};

export default api;
