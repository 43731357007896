import integrations from './dripWarming/integrations';
import lists from './dripWarming/lists';

export default function (axios) {
    return {

        integrations:  integrations(axios),
        lists:  lists(axios),
    }
}
