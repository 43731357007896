<template>
  <b-container fluid>


    <!-- User Interface controls -->
    <b-row class="mb-3">

      <b-col v-show="hasSearch" lg="4" class="my-1">
        <b-input-group size="md">
          <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
        </b-input-group>
      </b-col>

      <Transition>
        <div v-show="showAlertSelection" class="col-md-8 col-sm-12 mb-2 xs-mx-auto my-auto">
          <b-alert :show="true" variant="secondary" class="py-1 my-0 px-2">
            <div class="iq-alert-icon">
              <i class="ri-information-line"></i>
            </div>
            <div class="iq-alert-text">

                            <span>

                                {{ (selected.length == to && selected.length > 1) ? 'All' : '' }}
                                <b>{{  selected.length }}</b>
                                Source{{ selected.length > 1 ? 's' : '' }}
                                {{ selected.length < totalRows ? 'on this page' : 'recorded' }} are selected.

                            </span>

              <b-button v-if="selected.length == totalRows" variant="light"
                        @click="clearSelection">
                Clear Selection
              </b-button>

              <b-dropdown dropright variant='none' no-caret class="float-right my-auto">
                <template #button-content>
                  <button v-b-tooltip.hover
                          :title="`Grouped action on` + (selected.length ? ` ${(selected.length)} Source${(selected.length > 1 ? 's' : '')} selected` : '')"
                          aria-haspopup="true" aria-expanded="false" type="button"
                          class="btn dropdown-toggle float-right btn-none p-0 m-0">
                    <span><i class="ri-more-2-fill fa-lg"></i></span>
                  </button>

                </template>

                <b-dropdown-item :disabled="!selected.length"
                                 @click="handleSelectedAction('turn-on')">
                  <i v-b-tooltip class="text-success ri-toggle-fill fa-lg m-0"></i>
                  Turn on
                </b-dropdown-item>

                <b-dropdown-item :disabled="!selected.length"
                                 @click="handleSelectedAction('turn-off')">
                  <i class="text-secondary ri-toggle-line fa-lg m-0"></i>
                  Turn off
                </b-dropdown-item>

                <b-dropdown-item :disabled="!selected.length"
                                 @click="handleSelectedAction('delete')">
                  <i class="text-danger ri-delete-bin-line i-delete fa-lg m-0"></i>
                  Delete
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-alert>

        </div>
      </Transition>
    </b-row>

    <!-- Main table element -->
    <b-table v-if="renderTable" :ref="id" :responsive="true" :id="id" v-model="itemsSelected" striped bordered :busy="isBusy"
             :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter"
             :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
             :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered"
             @sort-changed="sortingChanged" @context-changed="contextChanged" :selectable="selectable"
             @row-selected="updateRowsSelect" style="min-height: 400px;">

      <template v-if="theadTop && theadTop.length" #thead-top="data">
        <b-tr>
          <b-th v-for="(th, index) in theadTop" :key="index" :colspan="th.colspan" :variant="th.variant">
            <span v-if="th.srOnly" class="sr-only">{{ th.title }}</span>
            <span v-else>{{ th.title }}</span>
          </b-th>
        </b-tr>
      </template>

      <!-- A custom formatted header cell for field 'selected' -->
      <template v-if="selectable" #head(select)="row">
        <b-form-checkbox v-model="selectAll" :indeterminate.sync="indeterminate"
                         @input="selectAllRows"></b-form-checkbox>
      </template>

      <template #table-busy>
        <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
        </div>
      </template>

      <template #empty="scope">
        <p class="mb-1">No matching records found</p>
      </template>


      <template v-for="(item, index) in customCells" #[`cell(${item.key})`]="row">

        <component v-if="item.component && item.key != 'select'" :is="item.component"
                   v-bind="{ row: row, ...(item.props ? item.props : {}) }"
                   @[item.event]="emit($event, item.event, item.component)"></component>

        <component v-else-if="item.component && item.key == 'select'" :is="item.component"
                   v-bind="{ row: row, ...(item.props ? item.props : {}) }"
                   @[item.event]="emit($event, item.event, item.component)" @selectRow="selectRow"
                   @unselectRow="unselectRow"></component>

      </template>

      <template #cell(refresh)="row">
        test
        <DtListSwitchRefreshCell
            @refreshActivatEvent="activateAutoRefreshEvent"
            @refreshDisabledEvent="disableAutoRefreshEvent"

            :row="row"></DtListSwitchRefreshCell>

      </template>

    </b-table>

    <!-- DataTable pagination -->
    <DtPaginate v-if="renderTable" :from="from" :to="to" :totalRows="totalRows" :perPage="perPage"
                :pageOptions="pageOptions" @handlePerPage="updatePerPage" @handlePage="updatePage"></DtPaginate>



  </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import api from '@/api/RestClient'
import {helper} from '@/helpers'
import DtPaginate from '@/components/inboxgeek/tables/DtPaginate.vue'
import ActiveCell from '@/components/inboxgeek/tables/ActiveCell.vue'
import DtListSwitchRefreshCell from '../../table/DtListSwitchRefreshCell.vue';

export default {
  name: 'ListsDataTable',
  components: {
    Spinner1,
    DtPaginate,
    ActiveCell,
    DtListSwitchRefreshCell
  },
  props: {
    id: {
      type: [String],
      default: 'dt-table'
    },
    url: {
      type: [String],
      default: '/api/users/metadata'
    },
    columns: {
      type: [Array],
      default: () => [{key: 'Name'}]
    },
    customCells: {
      type: [Array],
      default: () => []
    },
    organization: {
      type: [Number],
      default: () => null
    },
    item: {
      type: [Number],
      default: () => null
    },
    filters: {
      type: [Object],
      default: () => null
    },
    sort: {
      type: [String],
      default: 'created_at'
    },
    per: {
      type: [Number],
      default: null
    },
    isClient: {
      type: [Boolean],
      default: false
    },
    selectable: {
      type: [Boolean],
      default: false
    },
    hasSearch: {
      type: [Boolean],
      default: true
    },
    theadTop: {
      type: [Array],
      default: () => []
    },
    filter_on: {
      type: [Array],
      default: () => []
    },
  },
  data() {
    return {
      renderTable: true,
      isBusy: false,
      items: [],
      fields: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, {
        value: 250,
        text: "Show a lot"
      }],
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'asc',
      filter: '',
      filterOn: ["name"],
      account: -1,
      dates: null,
      $search: '',
      from: null,
      to: null,
      filtersParams: {},

      // Bulk variables
      selected: [],
      selectAll: false,
      indeterminate: false,
      itemsSelected: [],
      showAlertSelection: false
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {
              text: f.label,
              value: f.key
            }
          })
    }
  },
  beforeMount() {
    if (Array.isArray(this.columns)) {
      this.fields = this.columns
    }
  },
  mounted() {
    if (typeof this.filters !== 'undefined') {
      this.filtersParams = this.filters;
    }

    if (typeof this.sort !== 'undefined') {
      this.sortBy = this.sort;
    }

    if (typeof this.filter_on !== 'undefined' && this.filter_on.length) {
      this.filterOn = this.filter_on;
    }


    if (typeof this.per === 'number') {
      this.updatePerPage(this.per)
      this.resetTable()
    }

  },
  methods: {

    activateAutoRefreshEvent(){

    },
    disableAutoRefreshEvent(){

    },
    async getData(ctx) {
      await this.delay();
      return await this.fetchData(ctx);
    },
    async fetchData(ctx) {

      let items = []
      this.items = [];
      this.isBusy = true;
      let queryParams = {}
      if (typeof ctx !== "undefined") {
        queryParams = {
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort_by: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          search: this.filter,
          filter_on: JSON.stringify(this.filterOn),
        }
      }

      if (this.organization) {
        console.log('this.filtersParams', this.filtersParams);
        queryParams.account = this.organization;
        // this.filtersParams.account = this.organization;
      }

      if (this.item) {
        queryParams.item = this.item;
        // this.filtersParams.item = this.item;
      }

      if (this.filters) {
        queryParams.filters = JSON.stringify(this.filtersParams);
      }

      // For Notification only
      if (this.isClient) {
        queryParams.is_client = this.isClient;
      }

      return api.default.get(`${this.url}?` + (new URLSearchParams(queryParams).toString()))
          .then((response) => {

            items = response.data.data;
            this.totalRows = response.data.total
            this.currentPage = response.data.current_page
            this.perPage = response.data.per_page
            this.items = items
            this.from = response.data.from
            this.to = response.data.to

            // if (typeof this.callback === 'function') {
            //     return this.callback(response)
            // }

            return this.items
          })
          .catch((err) => {
            return []
          })
          .finally(() => {
            setTimeout(() => {
              this.isBusy = false;
              this.$root.$emit('complete');
            }, 400);
          })
    },
    delay: () => {
      return new Promise(resolve => setTimeout(resolve, 2000));
    },
    onFiltered(filteredItems) {
    },
    sortingChanged(ctx) {
      this.currentPage = 1
      this.$emit('sortChanged', ctx)
    },
    contextChanged(ctx) {
      this.$emit('contextChanged', ctx)
    },
    toast(title = "success", variant = "success", message = "Discount added") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },

    updatePage(data) {
      this.currentPage = data
      this.$root.$emit('bv::refresh::table', this.id)
    },
    updatePerPage(data) {
      this.perPage = data
      this.$root.$emit('bv::refresh::table', this.id)
    },
    resetTable() {
      this.renderTable = false;
      this.$nextTick(() => {
        this.renderTable = true
      })

    },
    emit(data, customEvent, component) {
      if (!customEvent) return

      data.component = component

      if (typeof customEvent !== "undefined") {
        this.$emit(customEvent, data)
      }
    },
    async selectAllRows() {

      this.selected = [];

      if (this.selectAll) {
        this.$refs[this.id].selectAllRows();
      } else {
        this.$refs[this.id].clearSelected();
      }
    },
    selectRow(index) {
      this.$refs[this.id].selectRow(index);
    },
    unselectRow(index, data) {
      this.$refs[this.id].unselectRow(index);
    },
    updateRowsSelect(items) {
      this.selected = items;
      this.showAlertSelection = items.length ? true : false;
      this.$refs[this.id].$forceUpdate();
    },
    clearSelection($event) {

      this.selectAll = false
      this.indeterminate = false;

      this.selected = [];
      this.itemsSelected = [];

      this.$refs[this.id].clearSelected();
    },
    handleSelectedAction(action = 'turn-on') {

      if (!this.itemsSelected.length || !this.selected.length) {
        return
      }

      // Get IDs
      let items = [];
      _.forEach(this.selected, (item) => {
        items.push(item.id)
      });

      // TODO Formatting data emit event to parent
      this.$emit('bulkAction', {action, items})

    },
    setFilter(search) {
      this.filter = search;
    }
  },
  watch: {
    'selected': {
      handler(newValue, oldValue) {
        this.$emit('onSelectItems', newValue)
      },
      deep: true
    },
    'filter': {
      handler(newValue, oldValue) {
        this.$emit('filterUpdate', newValue)
      },
      deep: true
    }
  }
}
</script>

<style>
td.lists > div,
td.integration > div {
  width: 10em;
}

td.tag > div,
td.account > div {
  width: 8em;
}

.user-flex {
  justify-content: center;
}
</style>
