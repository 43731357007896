<template>

  <div>
    <div class="row">
      <div class="col">

        <LvDashboardStatsCard
            period="today"
            valueUnit="Validations"
            label="Today"
            itemClass="iq-bg-success"
        iconClass="ri-calendar-line"
            service="3"

        ></LvDashboardStatsCard>
      </div>


      <div class="col">

        <LvDashboardStatsCard
            period="yesterday"
            valueUnit="Validations"
            label="Yesterday"
            itemClass="iq-bg-danger"
            iconClass="ri-calendar-line"
            service="3"
        ></LvDashboardStatsCard>
      </div>
      <div class="col">

        <LvDashboardStatsCard
            period="week"
            valueUnit="Validations"
            label="This Week"
            itemClass="iq-bg-warning"
            iconClass="ri-calendar-line"
            service="3"
        ></LvDashboardStatsCard>
      </div>
      <div class="col">

        <LvDashboardStatsCard
            period="month"
            valueUnit="Validations"
            label="This Month"
            itemClass="iq-bg-primary"
            iconClass="ri-calendar-line"
            service="3"
        ></LvDashboardStatsCard>
      </div>
    </div>
  </div>


</template>


<script>


import LvDashboardStatsCard from "@/components/charts/LvDashboardStatsCard.vue";

export default {
  name: "LiveValidationStats",
  components: {
    LvDashboardStatsCard
  },
  data() {
    return {

    }
  },
  methods : {

  }
}


</script>


<style scoped>

</style>