<template>
    <b-container fluid>
        <iq-card body-class="border_plan text-center" :class="item.id == id ? 'bg-primary text-white' : ''">
            <template v-slot:body>
                <span class="font-size-16 text-uppercase" v-if="item.plan !== ''">{{ item.name }}</span>
                <h2 class="mb-4 display-3 font-weight-bolder" :class="item.id == id ? 'text-white' : ''">
                    {{ item.balance.toLocaleString() }}
                    <small class="font-size-14 d-block mb-4" :class="item.id == id ? 'text-white' : 'text-muted'">
                        {{ eventNameService }}s
                    </small>
                    <span class="font-size-12 d-block mb-1">${{ item.unit_price }}/{{ eventNameService }}</span>
                    <span class="font-size-12 d-block">${{ item.price }}/month </span>
                </h2>
                <span v-if="false">Billed {{ billedPeriod }}</span>
                <h3 v-if="false" class="font-size-13" :class="item.id == id ? 'text-white' : 'text-muted'">
                    <span>{{ item.balance.toLocaleString() }}</span>
                    <span class="font-size-12">{{ eventNameService }}s</span>
                </h3>
                <b-button v-if="item.id != id" :variant="item.active ? 'light' : 'primary'" class="mt-5 " :class="item.buttonClass" @click="choosePlan(item)">
                    <span class="spinner-grow spinner-grow-sm" v-show="spinner && plan == item.id" role="status"
                        aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                    <span>Choose</span>
                </b-button>
                <div v-else class="mt-5">
                    <p>&nbsp;</p>
                </div>
            </template>
        </iq-card>
    </b-container>
</template>
<script>
import moment from 'moment'
import { mapGetters } from "vuex";
import { SERVICES } from "@/constantes";
import { helper } from '@/helpers'
import api from '@/api/RestClient'

const ON_TWELVE_MONTH = 12;
const ON_ONE_MONTH = 0;

export default {
    name: 'PaidPlanCard',
    components: {},
    props: {
        id: {
            type: Number,
            default: null
        },
        plan: {
            type: Number,
            default: null
        },
        item: {
            type: Object,
            default: {}
        }
    },
    computed: {
        ...mapGetters('Auth', ['user', 'isAdmin', 'isClient', 'UserSubscriptions', 'lastSubscriptions', 'livePlans']),
        eventNameService() {
            let name = null;
            let serviceID = this.$route.params.service;

            if (serviceID == SERVICES.LIVE_OPENS.id) { name = 'event'; }
            else if (serviceID == SERVICES.LIVE_LEADS.id) { name = 'record'; }
            return helper.capitalizeFirstLetter(name);
        },
    },
    data() {
        return {
            spinner: false,
            planPeriod: ON_ONE_MONTH,
            billedPeriod: 'monthly',
            agrementLink: "https://inboxgeek.com/services-agreement/",
            success: false,
        }
    },
    methods: {
        choosePlan(item) {

            let service = this.$route.params.service ?? -1;
            let subscriptions = this.livePlans;
            let currentPlan = _.find(subscriptions, function (subscription) {

                return subscription?.service_id == service
            });
            let date = currentPlan?.subscription_end_date ?? null;
            let subscription_end_date = date ? moment(date).format('MM/DD/YYYY') : '--'
            let display = !_.isUndefined(currentPlan) ? 'block' : 'none';
            let html = `<div style='text-align: left; font-size: 17px;'>
                        <p style='display:${display}'><b>Current plan:</b> ${currentPlan?.name} ending ${subscription_end_date}&nbsp;&nbsp;$${currentPlan?.price}</p>
                        <p><b>New plan:</b> ${item.name};
                                starting ${subscription_end_date};
                                $${item.price}
                            </p>
                        <div style='#'>
                            <input type="checkbox" id="services-agreement" name="services-agreement" value="1" disabled checked>
                            By clicking "Yes", you accept
                            <a target="_blank" href="${this.agrementLink}"><small>InboxGeek\'s Services Agreement</small></a>
                        </div>
                    </div>
                    `

            let discountCode = null
            this.$swal({
                title: 'Subscription Confirmation',
                html: html,
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes, Change my Plan',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: (discount_code) => {
                    // discountCode = discount_code
                },
            }).then((result) => {
                if (result.value) {
                    this.changeSubscription(item.id, discountCode)
                } else {
                    this.spinner = false;
                }
            })

        },
        getPlanEndDate() {
            let month = 1
            if (this.planPeriod == ON_TWELVE_MONTH) month = 12;
            return this.addMonths(month).toISOString().slice(0, 10);
        },
        addMonths(numOfMonths, date = new Date()) {
            date.setMonth(date.getMonth() + numOfMonths);

            return date;
        },
        changeSubscription(plan, discount = null) {

            this.$emit('startChangeSubscription');

            // this.plan = plan;
            const accountId = this.$store.getters['Auth/user'].account_id;

            let data = {
                plan_id: plan,
                period: this.planPeriodText,
                discount_value: this.planPeriod,
                discount_code: discount,
                account_id: accountId
            }

            const serviceID = this.$route.params.service;

            if (serviceID == SERVICES.LIVE_LEADS.id) {
                api.subscriptions.changeSubscriptionBeta(accountId, data)
                    .then(resp => {
                        this.success = true;
                        this.$emit('handleSuccessChangeSubscription', {plan});
                        this.getServiceCurrentSubscription(serviceID);
                    })
                    .catch(error => {
                        let message = error?.response?.data?.message ?? 'something went wrong!';
                        this.$parent.modal = false;
                        this.$bvToast.toast(message, {
                            title: 'Error',
                            variant: 'danger'
                        });
                    })
                    .finally(() => {
                        // this.spinner = false;
                        if(this.success) this.redirectToBilling();
                    })
            } else if (serviceID == SERVICES.LIVE_OPENS.id) {
                api.subscriptions.changeSubscription(accountId, data)
                    .then(resp => {

                        if (resp.success) {
                            this.success = true;
                            this.$emit('handleSuccessChangeSubscription', {plan});
                            this.getServiceCurrentSubscription(serviceID);
                        }

                    })
                    .catch(error => {
                        let message = error?.response?.data?.message ?? 'something went wrong!';
                        if (error.response.status == 500) {
                            message = 'something went wrong!'
                        }
                        this.$parent.modal = false;
                        this.$bvToast.toast(message, {
                            title: 'Error',
                            variant: 'danger'
                        });
                    })
                    .finally(() => {
                        // this.spinner = false;
                        if(this.success) this.redirectToBilling();
                    })
            }
            else if (serviceID == SERVICES.DRIP_WARMING.id) {
              api.subscriptions.changeSubscription(accountId, data)
                  .then(resp => {

                    if (resp.success) {
                      this.success = true;
                      this.$emit('handleSuccessChangeSubscription', {plan});
                      this.getServiceCurrentSubscription(serviceID);
                    }

                  })
                  .catch(error => {
                    let message = error?.response?.data?.message ?? 'something went wrong!';
                    if (error.response.status == 500) {
                      message = 'something went wrong!'
                    }
                    this.$parent.modal = false;
                    this.$bvToast.toast(message, {
                      title: 'Error',
                      variant: 'danger'
                    });
                  })
                  .finally(() => {
                    // this.spinner = false;
                    if(this.success) this.redirectToBilling();
                  })
            }

        },
        getServiceCurrentSubscription(serviceID) {
            if (this.isClient) {
                this.spinner = true;

                const accountId = this.user.account_id;

                api.accounts.getCurrentSubscription(accountId, { service_id: serviceID })
                    .then((response) => {
                        if (response.success) {
                            this.UserSubscriptions[serviceID] = response.data;
                            this.$store.dispatch('Auth/updateUserSubscriptions', this.UserSubscriptions);
                            this.getCurrentPlan(serviceID)
                        }
                    })
                    .finally(() => {
                        this.spinner = false;
                    })


            }
        },
        getCurrentPlan(serviceId = null) {
            this.spinner = true;
            if (!serviceId) {
                serviceId = this.$route.params.service;
            }
            api.plans.planByAccount(this.user.account_id, serviceId)
                .then(resp => {

                    if (resp.success && resp.data) {
                        let liveServicesPlans = this.livePlans;
                        liveServicesPlans[serviceId] = resp.data;

                        let lastServicesPlans = this.lastSubscriptions;
                        lastServicesPlans[serviceId] = resp.data;

                        this.currentPlan = resp.data;
                        if (serviceId == SERVICES.LIVE_OPENS.id) {
                            this.$store.dispatch('Auth/updateLiveOpenersSubscribed', resp.data && !resp.data.is_trial);
                        } else if (serviceId == SERVICES.LIVE_LEADS.id) {
                            this.$store.dispatch('Auth/updateLiveLeadsSubscribed', resp.data && !resp.data.is_trial);
                        }else if (serviceId == SERVICES.LIVE_VALIDATION.id) {
                          this.$store.dispatch('Auth/updateLiveValidationSubscribed', resp.data && !resp.data.is_trial);
                        }else if (serviceId == SERVICES.DRIP_WARMING.id) {
                          this.$store.dispatch('Auth/updateLiveValidationSubscribed', resp.data && !resp.data.is_trial);
                        }

                        this.$store.dispatch('Auth/updateLivePlans', liveServicesPlans);

                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => { this.spinner = false; });
        },
        redirectToBilling() {
            setTimeout(() => {
                window.location.href = '/user/billing';
            }, 1500);
        },
    }
}

</script>
