export default function (axios) {
    let prefix = 'drip-warming/integrations';
    return {

            list: (params = {}) => {
                return axios.get(`${prefix}`, { params })
                    .then(response => response.data);
            },

        collection: (params = {}) => {
            return axios.get(`${prefix}/collection`, { params })
                .then(response => response.data);
        },
            get: (integrationId) => {
                return axios.get(`${prefix}/${integrationId}`)
                    .then(response => response.data);
            },
            post: (integrationData = {}) => {
                return axios.post(`${prefix}`, integrationData)
                    .then(response => response.data);
            },
            put: (integrationId, integrationData) => {
                return axios.put(`${prefix}/${integrationId}`, integrationData)
                    .then(response => response.data)
            },
            delete: async (integrationId, data) => {
                return axios.delete(`${prefix}/${integrationId}`, { data: data })
                    .then(response => response.data)
            },
            restore: (id) => {
                return axios.post(`${prefix}/${id}/restore`, {})
                    .then(response => response.data);
            },
            sendTestFlow: (integrationId, email) => {
                return axios.get(`${prefix}/${integrationId}/send-test-flow?email=${email}` )
                    .then(response => response.data);
            },
            bulk: (bulkAction, bulkPost = {}) => {
                return axios.post(`${prefix}/bulk/${bulkAction}`, bulkPost)
                    .then(response => response.data);
            },
            bulkDailyLimit: (bulkData = {}) => {
                return axios.post(`${prefix}/bulk-daily-limit`, bulkData)
                    .then(response => response.data);
            },
            checkTag: (tagId, accountId) => {
                return axios.get(`${prefix}/account/${accountId}/tags/${tagId}/check`)
                    .then(response => response.data);
            },
            checkOmniTag: (code, platform_id) => {
                return axios.get(`drip-warming/platforms/omnisend/tags/${code}/contacts?platform_id=${platform_id}`)
                    .then(response => response.data);
            },
            schedule:(integrationId,schedule) => {
                return axios.put(`${prefix}/${integrationId}/schedule`, schedule)
                    .then(response => response.data);
            },
            logs:(filter) => {
                return axios.get(`${prefix}/logs`, {params:filter})
                    .then(response => response.data);
            }


    }
}
