export const RESPONSE_STATUS = {
  TOKEN_EXPIRED: 401,
  SUCCESS: 201,
};

export const ENTITY_STATUS = {
  ACTIVE: 1,
  READY: 2,
  UPLOADING: 1,
  DELETE: -1
};

export const ACCOUNT_TYPE = {
  1: {
    name: "client"
  },
  2: {
    name: "Internal"

  },
  3: {
    name: "Test"
  },
}

export const PLATFORMS_CODE = {
  MAROPOST: 1,
  SENDLANE: 2,
  KLAVIYO: 3,
  CAMPAIGNER: 4,
  ONTRAPORT: 5,
  ACTIVE_CAMPAIGN: 6,
  AWEBER: 7,
  CONVERKIT: 8,
  KEAP: 9,
  BLASTABLE: 10,
  HUBSPOT: 11,
  GET_RESPONSE: 12,
  MAILCHIMP: 13,
  AMAZON_SES: 14,
  EXPERTSENDER: 15,
  WEBHOOK: 16,
  INBOXSUITE: 17,
  MAILWIZZ: 18,
  EMAIL_OCTOPUS: 19,
  MUMARA: 20,
  SENDINBLUE: 21,
  INBOX: 22,
  SENDGRID: 23,
  GOHIGHLEVEL: 24,
  LISTRAK: 25,
  IBG_ESP: 99,
  ONGAGE: 26,
  OMNISEND: 27,
  SENDRAPP: 28,
  BEEHIIV:29,
  MAILSEND:30,
  ITERABLE:31,
};


export const COLUMNS_GRID = {
  COL2: 2,
  COL3: 3,
  COL4: 4,
  COL6: 6,
  COL12: 12,
};

export const SERVICES = {
  LIVE_OPENS: {
    id: 1,
    name: "LiveOpens"
  },
  LIVE_LEADS: {
    id: 2,
    name: "LiveLeads"
  },
  LIVE_VALIDATION: {
    id: 3,
    name: "LiveValidation"
  },
      DRIP_WARMING: {
        id: 4,
        name: "DripWarming"
      }
};


export const SERVICES_BY_ID = {
  1: {
    name: "LiveOpens",
    consumed: "Events"
  },
  2: {
    name: "LiveLeads",
    consumed: "Records"
  },
  3: {
    name: "LiveValidation",
    consumed: "Records"
  },
  4: {
    name: "DripWarming",
    consumed: "Events"
  }
};

export const KPI_CONSTANCE = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
  EVENS: 5
};

export const PLATFORMS_ITEMS = [{
  img: 'maropost.png',
  code: 1,
  name: 'Maropost'
},
{
  img: 'sendlane.png',
  code: 2,
  name: 'Sendlane',
  $isDisabled: false
},
{
  img: 'klaviyo.png',
  code: 3,
  name: 'Klaviyo',
  $isDisabled: false
},
{
  img: 'campaigner.png',
  code: 4,
  name: 'Campaigner',
  $isDisabled: false
},
{
  img: 'ontraport.png',
  code: 5,
  name: 'Ontraport',
  $isDisabled: false
},
{
  img: 'activecampaign.png',
  code: 6,
  name: 'ActiveCampaign',
  $isDisabled: false
},
{
  img: 'aweber.png',
  code: 7,
  name: 'Aweber',
  $isDisabled: false
},
{
  img: 'convertkit.png',
  code: 8,
  name: 'Convertkit',
  $isDisabled: false
},
{
  img: 'keap.png',
  code: 9,
  name: 'Keap',
  $isDisabled: false
},
{
  img: 'blastable.png',
  code: 10,
  name: 'Blastable',
  $isDisabled: false
},
{
  img: 'hubspot.png',
  code: 11,
  name: 'Hubspot',
  $isDisabled: false
}, {
  img: 'getresponse.png',
  code: 12,
  name: 'GetResponse',
  $isDisabled: false
}, {
  img: 'mailchimp.png',
  code: 13,
  name: 'Mailchimp',
  $isDisabled: false
}, {
  img: 'expertsender.png',
  code: 15,
  name: 'ExpertSender',
  $isDisabled: false
}, {
  img: 'webhook.png',
  code: 16,
  name: 'Webhook',
  $isDisabled: false
}, {
  img: 'inboxsuite.png',
  code: 17,
  name: 'Inboxsuite',
  $isDisabled: false
}, {
  img: 'mailwizz.png',
  code: 18,
  name: 'Mailwizz',
  $isDisabled: true
}, {
  img: 'emailoctopus.png',
  code: 19,
  name: 'EmailOctopus',
  $isDisabled: false
}, {
  img: 'mumara.png',
  code: 20,
  name: 'Mumara',
  $isDisabled: true
}, {
  img: 'brevo.png',
  code: 21,
  name: 'Brevo',
  $isDisabled: false
}, {
  img: 'inbox.png',
  code: 22,
  name: 'Inbox',
  $isDisabled: false
},
{
  img: 'sendgrid.png',
  code: 23,
  name: 'SendGrid',
  $isDisabled: false
},
{
  img: 'gohighlevel.png',
  code: 24,
  name: 'GoHighLevel',
  $isDisabled: false
},
{
  img: 'listrak.jpg',
  code: 25,
  name: 'Listrak',
  $isDisabled: false
},{
  img: 'ongage.png',
  code: 26,
  name: 'Ongage',
  $isDisabled: false
},{
  img: 'omnisend.png',
  code: 27,
  name: 'OmniSend',
  $isDisabled: false
  },{
  img: 'sendrapp.png',
  code: 28,
  name: 'SendrApp',
  $isDisabled: false
},
  {
  img: 'beehiiv.png',
  code: 29,
  name: 'Beehiiv',
  $isDisabled: false
},
  {
  img: 'mailsend.png',
  code: 30,
  name: 'MailSend',
  $isDisabled: false
  },
  {
  img: 'ibgesp.png',
  code: 99,
  name: 'InboxGeek',
  $isDisabled: false
},  {
  img: 'iterable.png',
  code: 31,
    name: 'Iterable',
  $isDisabled: false
},
]

export const OAUTH_ESP = [
  PLATFORMS_CODE.AWEBER,
  PLATFORMS_CODE.HUBSPOT,
  PLATFORMS_CODE.KEAP,
  PLATFORMS_CODE.MAILCHIMP
]

export const INTEGRATION_STATES = {
  113: {
    message: 'Request is missing or has an invalid API key',
    class: 'danger'
  },
  121: {
    message: 'Tag Connection Error',
    class: 'primary'
  },
  213: {
    message: 'Contacts List Not found',
    class: 'danger'
  },
  221: {
    message: 'List Connection Error',
    class: 'primary'
  },
  331: {
    message: 'Reception Tag Not found',
    class: 'primary'
  },
  112: {
    message: 'Connection Error',
    class: 'warning'
  },
  311: {
    message: 'Account Balance < 0', // account balance is insufficient
    class: 'primary'
  },
  312: {
    message: 'Subscription End',
    class: 'warning'
  },
  412: {
    message: 'Access invalid: Get contacts',
    class: 'warning'
  },
  413: {
    message: 'Access invalid: Send events',
    class: 'danger'
  },
};

export const INVOICE_STATES = [
  {
    item: '-2',
    name: 'Canceled'
  }, {
    item: '-1',
    name: 'Failed'
  },
  {
    item: '0',
    name: 'Initial'
  },
  {
    item: '1',
    name: 'Paid',
  },
  {
    item: '2',
    name: 'Partial refund'
  },
  {
    item: '3',
    name: 'Refunded'
  }
]

export const INVOICE_STATUS = [
  {
    code: -2,
    name: 'Canceled',
    class: 'badge cursor-pointer badge-secondary badge-pill iq-bg-danger'
  }, {
    code: -1,
    name: 'Failed',
    class: 'badge cursor-pointer badge-secondary badge-pill iq-bg-danger'
  },
  {
    code: 0,
    name: 'Initial',
    class: 'badge cursor-pointer badge-secondary badge-pill iq-bg-default'
  },
  {
    code: 1,
    name: 'Paid',
    class: 'badge cursor-pointer badge-secondary badge-pill iq-bg-success'
  },
  {
    code: 2,
    name: 'Partial Refund',
    class: 'badge cursor-pointer badge-secondary badge-pill iq-bg-warning'
  },
  {
    code: 3,
    name: 'Refunded',
    class: 'badge cursor-pointer badge-secondary badge-pill iq-bg-danger'
  }
]


export const GE_RECORD_STATUS_STATES = [
  { code: 0, name: 'Event Received' },
  { code: 1, name: 'Sent Successfully' },
  { code: 2, name: 'No Integration Found' },
  { code: 3, name: 'Platform Error' },
  { code: 4, name: 'Duplicates' },
  { code: 5, name: 'Suppression' }
];

export const GE_RECORD_CLEAN_STATES = [
  { code: -2, name: 'Not Cleaned', description: '' },
  { code: -1, name: 'Not Cleaned', description: '' },
  { code: 0, name: 'Retry', description: 'Unable to establish a validation response from the ISP – retry again.' },
  { code: 1, name: 'Verified', description: 'Email address is deliverable.' },
  { code: 2, name: 'Undeliverable', description: ' Email address does not exist, invalid, suspended, over quota or disabled.' },
  { code: 3, name: 'Catch All', description: 'Domain of email address accepts all mail and it is impossible to determine validity. If the email address was acquired organically or you have confidence in the validity of the email address, then send at your discretion.' },
  { code: 4, name: 'Role', description: 'Email is associated to common distribution groups. abuse@, sales@, no-reply@, test@ and etc.' },
  { code: 5, name: 'Malformed', description: 'Email address does not conform to valid email format.' },
  { code: 6, name: 'SpamTrap', description: 'Avoid SpamTrap emails at all cost. Those accounts are kept alive to damage sender reputation.' },
  { code: 7, name: 'Complainer', description: 'Complainers are commonly users who like to complain after receiving email. Complaints can vary from ISP notification to cease and desist letters. In some rare cases they can also pursue litigation. These are valid addresses but we do not recommend sending mail.' },
  { code: 9, name: 'Bot', description: 'Bots are email accounts that are maintained by bot servers for sending spam, clicking every link and other harmful or harmless activities.' },
  { code: 10, name: 'Seed Account', description: 'Seed accounts are known email addresses that are maintained in masses for various reasons. Most commonly they are used for compliance tracking.' },
  { code: 11, name: 'Unknown', description: 'Email address cannot be verified at the moment. Retrying later may succeed.' },
  { code: 12, name: 'Unauthorized', description: 'Unauthorized API access. This error will be returned if IP or URL are not whitelisted and there are over 50 requests within 24 hours.' },
  { code: 13, name: 'Disposable', description: 'Temporary email account that is designed to only be used few times before expiring.' },
  { code: 20, name: 'Suppressed', description: 'Email is in known suppression list. This is only available when advanced validation is enabled on particular list.' },
];


export const NOTIFICATION_VARIABLES = [
  {
    typeId: 1,
    name: 'invoice',
    data: ['support_link', 'due_date', 'amount'],
  },
  {
    typeId: 2,
    name: 'Missed Payment',
    data: ['support_link', 'due_date', 'amount'],
  },
  {
    typeId: 3,
    name: 'Out of Events',
    data: [],
  },
  {
    typeId: 4,
    name: 'invoice',
    data: ['support_link', 'due_date', 'amount'],
  },
  {
    typeId: 5,
    name: 'Checkout Session',
    data: ['support_link', 'due_date', 'amount'],
  },
  {
    typeId: 6,
    name: 'Subscription',
    data: ['account', 'action', 'date', 'nextBillingDate', 'by'],
  },
  {
    typeId: 7,
    name: 'Live Report',
    data: ['integration_name', 'from', 'to'],
  }
];


export const DEFAULT_USER_SETTINGS = [
  {
    name: "liveOpens daily cap indicator",
    nickname: "liveopens_daily_limit_color",
    value: false
  }
];

export const PREFERENCES_AUTO_UPDATE_LIST = [
  {
    code: 'sends',
    name: "Sends",
  },
  {
    code: 'delivered',
    name: "Delivered",
  },
  {
    code: 'opens',
    name: "Opens",
  },
  {
    code: 'opens_rate',
    name: "Opens Rate",
  },
  {
    code: 'complaints',
    name: "Complaints",
  },
  {
    code: 'complaints_rate',
    name: "Complaints Rate",
  },
  {
    code: 'clicks',
    name: "Clicks",
  },
  {
    code: 'clicks_rate',
    name: "Clicks Rate",
  },
  {
    code: 'bounces',
    name: "Bounces",
  },
  {
    code: 'bounces_rate',
    name: "Bounces rate",
  },
];

export const SUPPORT_TICKET_STATUS = {
  TICKET_OPEN: 1,
  TICKET_CLOSE: -1,
  TICKET_DELETE: -10
};

export const SUPPORT_TICKET_TYPES = [
  {name: 'general'},
  {name: 'liveOpens'},
  {name: 'liveLeads'},
  {name: 'subscription'},
];

export const APP_TIMEZONE = 'Canada/Eastern';