export default function (axios) {
  return {
    list: (params = {}) => {
      return axios.get(`/lists`, {params})
        .then(response => response.data);
    },
    get: (listId) => {
      return axios.get(`/lists/${listId}`)
        .then(response => response.data);
    },
    put: (listId, listData) => {
      return axios.put(`/lists/${listId}`, listData)
        .then(response => response.data)
    },
    delete: (listId) => {
      return axios.delete(`/lists/${listId}`)
        .then(response => response.data)
    },
    eiInit: (listId) => {
      return axios.post(`/lists/ei-init/${listId}`)
        .then(response => response.data)
    },
    logs: (listId) => {
      return axios.get(`/lists/${listId}/logs`)
        .then(response => response.data);
    },


    updateAutoRefresh(listId,days)
    {
      return axios.put(`/lists/${listId}/auto-refresh`,{ "refreshDays": days })
          .then(response => response.data);
    },

  }
}
