<template>
  <div style="min-width: 100px; position: relative;">

    <div class="p-2">

      <div class="flex justify-content-between">
        <h5 class="m-0" >Auto Refresh </h5>
      </div>

      <div class="my-3">
        <label for="refresh-modal-input" class="text-muted">Refresh the List Every {{ refreshDays}} Days:</label>
        <input id="refresh-modal-input"  type="number" min="1" v-model="refreshDays" placeholder="refresh every 3 Days" class="form-control">
      </div>


      <button :disabled="setAutoRefreshLoading"
      style="width: 100%"
              @click="setAutoRefresh"
      class="btn btn-sm btn-primary"
      ><b-spinner small type="grow" v-show="setAutoRefreshLoading"></b-spinner>  Set auto refresh </button>

      <button :disabled="disableAutoRefreshLoading"
              @click="disableAutoRefresh"
              style="width: 100%"
              class="btn btn-sm mt-2 btn-light"
      ><b-spinner small type="grow" v-show="disableAutoRefreshLoading"></b-spinner>  Disable auto refresh </button>

  </div>


  </div>
</template>



<script>


import api from "@/api";

export default {

  name: "SetDripWarmingRefreshModal",
  props:['list'],
  components:{

  },
  data: function (){
    return {
      refreshDays  : 3,
      setAutoRefreshLoading:false,
      disableAutoRefreshLoading:false,

    };
  },
  methods : {
    disableAutoRefresh(){
      this.disableAutoRefreshLoading = true;
      api.dripWarming.lists.updateAutoRefresh(this.list.id,0)
          .then(result => {
            this.disableAutoRefreshLoading = false;
            this.$emit('disableAutoRefreshSuccess','disable',true , this.refreshDays);
          })
          .catch((error) => {
            this.disableAutoRefreshLoading = false;
            this.$emit('disableAutoRefreshFailed','disable',false , this.refreshDays);
          });
    },
    setAutoRefresh(){
      this.setAutoRefreshLoading = true;
      api.dripWarming.lists.updateAutoRefresh(this.list.id,this.refreshDays)
          .then(result => {
            this.setAutoRefreshLoading = false;
            this.$emit('autoRefreshSuccess','set',true , this.refreshDays);
          })
          .catch((error) => {
            this.setAutoRefreshLoading = false;
            this.$emit('autoRefreshFailed','set',false , this.refreshDays);
          });
    },

  },

}


</script>