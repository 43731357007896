<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3 mt-3">
            <b-col lg="6">
                <h4 class="card-title float-start">Live validation Lists</h4>
            </b-col>
        </b-row>

        <!-- User Interface controls -->
        <b-row class="mb-3">

            <b-col lg="4" class="my-1">
                <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
                </b-input-group>
            </b-col>

        </b-row>

        <!-- Main table element -->
        <b-table class="ig-text-wrap" ref="table" :id="id" striped bordered :responsive="$store.getters['Auth/isAdmin']"
            :busy="isBusy" :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage"
            :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered"
            @sort-changed="sortingChanged">

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <!-- Cell status -->
            <template #cell(status)="row">
                <span><i class="fa" v-b-tooltip.hover :title="statusClassText(row.item.status)" :class="statusClass(row.item.status)" aria-hidden="true"></i></span>
            </template>

            <!-- Cell action -->
            <template #cell(action)="row">
                <span @click="showModal(row.item)" class="cursor-pointer" v-if="row.item.status == 2" >
                    <i class="fa fa-download text-primary" v-b-tooltip.hover title="Download" aria-hidden="true" v-show="row.item.status != DOWNLOADING"></i>
                    <b-spinner small label="Small Spinner" type="grow" v-show="row.item.status == DOWNLOADING"></b-spinner>
                </span>

              <span @click="refresh(row.item)" class="cursor-pointer mx-2" v-if="row.item.status == -2 || row.item.status == -1 || row.item.status == -3">
                    <i class="fa fa-refresh text-info" v-b-tooltip.hover title="Refresh" aria-hidden="true" v-show="! ( refreshLoading[row.item.id]  )"></i>
                    <b-spinner small label="Small Spinner" type="grow" v-show="refreshLoading[row.item.id]"></b-spinner>
                </span>
            </template>

        </b-table>

        <!-- Data table pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>


      <b-modal
          size="md"
          id="modal-save-csv"
          style="padding:20px"
          label="Export Contact To CSV File"
          ref="LVSaveCsvModal"
          hide-footer
          title="Export Contact To CSV File">

      <div class="my-2">
        <div class="d-flex justify-content-between">
        <label for=""> Select contacts by type:</label>

          <b-form-checkbox switch v-model="isSelectAll" size="md" button-variant="primary">select all</b-form-checkbox>
        </div>
              <multiselect
                        @input="showAlert"
                          v-model="selectedContactStatus"
                           placeholder="Select Status" label="name" track-by="code" :options="availableContactStatus"
                           :multiple="true" :taggable="false"
                            :disabled="isSelectAll">
              </multiselect>
      </div>

        <div
            v-for="(availableContactStat, index) in selectedContactStatus"
            :key="availableContactStat.code"
            class="alert alert-info alert-dismissible fade show my-2 d-flex p-4"
            role="alert"
            v-if="availableContactStat.isShown"
            style="flex-direction:  column;color: #16a093;  border-color: #ffffff; background-color: rgba(230,255,253,0.33);" >

            <div class="d-flex justify-content-between">
              <h5 style="color: #16a093;">{{availableContactStat.name}}</h5>

              <button type="button" @click="hideAlert(index)" class="close text-success" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">x</span>
              </button>

            </div>

            <p class="m-0">
             {{availableContactStat.description }}
            </p>
        </div>

        <button class="btn btn-success w-100 p-2" style="font-size: 16px;" @click="download(listToDownload)" ><i class="ri-file-download-line"></i> Export </button>


      </b-modal>
    </b-container>


</template>

<script>

import Multiselect from "vue-multiselect";
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers'
import {
    mapGetters
} from "vuex";

export default {
    name: 'DtImport',
    components: {
      Multiselect,
        Spinner1,
    },
    props: {
        dates: {
            type: Object,
            default: () => {
                return { from: null, to: null }
            }
        },
        organization: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
          isSelectAll:false,
            id: 'dt-contacts',
            isBusy: false,
            items: [],
          listToDownload:null,
            fields: [
                {
                    key: 'list_name',
                    label: 'List Name',
                    sortable: true,
                    sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return value;
                    },
                },
                {
                    key: 'account',
                    label: 'Organization',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return value.name;
                    },
                },
                {
                    key: 'total_contacts',
                    label: 'Total contacts',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return this.formatDecimal(value);
                    },
                },
                {
                    key: 'total_verified',
                    label: 'Total verified',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return this.formatDecimal(value);
                    },
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true,
                    class: 'text-center',
                },
                {
                    key: 'created_at',
                    label: 'Date',
                    sortable: true,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return this.formatDate(value);
                    }
                },
                {
                    key: 'action',
                    label: 'Actions',
                    sortable: true,
                    class: 'text-center'
                },
                
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, 200, 500, {
                value: 10000,
                text: "Show a lot"
            }],
            sortBy: 'id',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["list_name"],
            accounts: [],
            account: null,
            plans: [],
            from: null,
            to: null,
            $search: '',
            reportDays: null,
            groupByProduct: false,
            DOWNLOADING: 101,
          refreshLoading:[],


          //
          availableContactStatus: [
            {
              index: 0,
              code: 1,
              name: "Verified",
              description: "Email address is deliverable.",
              isShown: true,
            },
            {
              index: 1,
              code: 2,
              name: "Undeliverable",
              description:
                  "Email address does not exist, invalid, suspended, over quota or disabled.",
              isShown: true,
            },
            {
              index: 2,
              code: 3,
              name: "Catch All",
              description:
                  "Domain of email address accepts all mail and it is impossible to determine validity. If the email address was acquired organically or you have confidence in the validity of the email address, then send at your discretion.",
              isShown: true,
            },
            {
              index: 3,
              code: 4,
              name: "Role",
              description:
                  "Email is associated to common distribution groups. abuse@, sales@, no-reply@, test@ and etc.",
              isShown: true,
            },
            {
              index: 4,
              code: 5,
              name: "Malformed",
              description: "Email address does not conform to valid email format.",
              isShown: true,
            },
            {
              index: 5,
              code: 6,
              name: "SpamTrap",
              description:
                  "Avoid SpamTrap emails at all cost. Those accounts are kept alive to damage sender reputation.",
              isShown: true,
            },
            {
              index: 6,
              code: 7,
              name: "Complainer",
              description:
                  "Complainers are commonly users who like to complain after receiving email. Complaints can vary from ISP notification to cease and desist letters. In some rare cases they can also pursue litigation. These are valid addresses but we do not recommend sending mail.",
              isShown: true,
            },
            {
              index: 7,
              code: 9,
              name: "Bot",
              description:
                  "Bots are email accounts that are maintained by bot servers for sending spam, clicking every link and other harmful or harmless activities.",
              isShown: true,
            },
            {
              index: 8,
              code: 10,
              name: "Seed Account",
              description:
                  "Seed accounts are known email addresses that are maintained in masses for various reasons. Most commonly they are used for compliance tracking.",
              isShown: true,
            },
            {
              index: 9,
              code: 11,
              name: "Unknown",
              description:
                  "Email address cannot be verified at the moment. Retrying later may succeed.",
              isShown: true,
            },
            {
              index: 10,
              code: 12,
              name: "Unauthorized",
              description:
                  "Unauthorized API access. This error will be returned if IP or URL are not whitelisted and there are over 50 requests within 24 hours.",
              isShown: true,
            },
            {
              index: 11,
              code: 13,
              name: "Disposable",
              description:
                  "Temporary email account that is designed to only be used a few times before expiring.",
              isShown: true,
            },
            {
              index: 12,
              code: 20,
              name: "Suppressed",
              description:
                  "Email is in known suppression list. This is only available when advanced validation is enabled on a particular list.",
              isShown: true,
            },
          ],
          selectedContactStatus:[]

        }
    },
    computed: {
      showAlerts(){
        return  this.availableContactStatus.filter(status => status.isShown);
      },

        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        },
        ...mapGetters('Auth', ['user']),
        ...mapGetters('Auth', ['isAdmin'])
    },
    mounted() {
        this.loadFields()
        if (!this.isAdmin) this.account = this.user.account_id;
    },
    methods: {
      showAlert(newSelection) {

        newSelection.forEach(status => {
          if (!this.selectedContactStatus.includes(status)) {
            this.$set(status, 'isShown', true); // Show only newly added status
          }
        });


      },
      hideAlert(index) {
        this.$set(this.selectedContactStatus[index], 'isShown', false);

      },
      showModal(list){
        this.listToDownload = list;
        this.$bvModal.show('modal-save-csv');
      },
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        moneyDefaultFormat(amount) {
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        setGroupByProduct(status) {
            this.groupByProduct = status;
        },
        sumInArrayByKey(items = [], key = 'events') {
            let sum = 0;
            items.forEach(item => {
                sum += item[key]
            });
            return sum;
        },
        eventCalculStats(array, key) {
            return this.sumInArrayByKey(array, key);
        },
        stringInArrayByKey(items = [], key = 'list_name') {
            let name = '';
            let i = 0;
            const length = items.length;
            items.forEach(item => {
                i++;
                name += i != length ? item[key] + ', ' : item[key];

            });
            return name;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {
            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                    from: this.dates.from,
                    to: this.dates.to,
                    account: this.account ?  this.account : null,
                    plans: this.plans.length ? JSON.stringify(this.plans) : -1,
                    $search: this.search,
                    groupByProduct: this.groupByProduct
                }
            }
            return api.emailValidations.lists(queryParams)
                .then((response) => {

                    items = response.data.data;
                    const reportDays = response.data.days;
                    this.totalRows = response.data.total
                    this.currentPage = response.data.current_page
                    this.perPage = response.data.per_page

                     items.forEach((list)=>{
                    this.refreshLoading[list.id] = false;
                  });
                  this.items = items;
                    this.reportDays = reportDays != 0 ? reportDays : 1;
                    this.from = response.data.from
                    this.to = response.data.to
                    this.$emit('onFetchData', items);
                    
                    return items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$root.$emit('updatedTable');
                    }, 400);
                })

        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (store.getters['Auth/isAdmin']) {
                    return item;
                } else
                    if (![
                        "account_id",
                        "billing_date",
                        "events_cycle",
                        "current_plan",
                        "time_restrictions",
                        "created_by",
                        "monthly_events",
                        "list_quality"
                    ].includes(item.key)) return item;
            })
            this.fields = fields;
        },
        setAccounts(account) {
            this.currentPage = 1
            this.account = account.code
            this.$root.$emit('bv::refresh::table', this.id)
        },
        setDates(dates) {
            this.dates = dates
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.id)
        },
        isDisabledDownload(status) {
            const FINISHED = 1;
            return ![FINISHED].includes(status)
        },
        // Filter
        onFiltered(filteredItems) {
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
        statusClass(status) {
            let classe = "fa-spinner text-primary"
            switch (status) {
              case -3:
                classe="fa-warning text-warning";
                break;
                case -2:
                    classe = "fa-times-circle-o text-danger"
                    break;
                 case -1:
                    classe = "fa-times-circle-o text-danger"
                    break;
                case 2: case 1:
                    classe = "fa-check text-success"
                    break;
            
                default:
                    break;
            }
            return classe;
        },
        statusClassText(status) {
            let label = "inprogress..."
            switch (status) {
              case -3:

                label = "Insufficient balance"
                break;
                case -2:
                    label = "Error"
                    break;
                case -1:
                    label = "Cancelled"
                    break;
                 case 2: case 1:
                    label = "Finished"
                    break;

                default:
                    break;
            }
            return label;
        },
        download(item) {
            let currentStatus =item.status; 
            item.status  = this.DOWNLOADING;

            return api.emailValidations.generateCsv( item.id , this.selectedContactStatus,this.isSelectAll)
                .then((response) => {
                    this.$bvToast.toast('Csv Downloaded successfully', {
                        title: 'Success',
                        variant: 'success'
                     });

                    var fileURL = window.URL.createObjectURL(new Blob([response]));
                    var fileLink = document.createElement('a');
                    var fileName = 'emails-validate-' + Date.now() + '.csv';

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                 })
                .catch((error) => { 
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Create csv file',
                        variant: 'danger'
                    });
                    
                })
                .finally(() => { 
                    item.status = currentStatus;
                })
        },
      refresh(list){
        this.refreshLoading[list.id]=true;
        return api.emailValidations.refresh(list.id)
            .then((response) => {
              this.$bvToast.toast('List successfully refreshed', {
                title: 'Success',
                variant: 'success'
              });

            })
            .catch((error) => {
              this.$bvToast.toast('failed to refresh the list'.response.data.message, {
                title: 'Error',
                variant: 'danger'
              });

            })
            .finally(() => {
             this.refreshLoading[list.id]=false;
            })
      }
    },
     watch: {
        "organization": {
            handler(newVal, oldVal) {
                this.setAccounts(newVal);
            }
        }
    }
}
</script>

<style scoped>
.modal-content {
  padding: 20px !important;
}
</style>
