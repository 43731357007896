<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3">
            <b-col lg="4" class="my-1">
                <h4 class="card-title">Lists</h4>
            </b-col>
        </b-row>


        <!-- Main table element -->
        <ListsTable :ref="id" v-bind="{filters: {account_id : account?.code, ...filterData},}" @show-logs="showLogs" @time-logs="timeLogs" @update-list="updateUploadContacts" @remove="removeItem"></ListsTable>

        

        <b-modal ref="log-lists" size="lg" id="modal-time-log-lists" :title="time_log_title" hide-footer>
            <ListTimeLine :row="listSelected"></ListTimeLine>
        </b-modal>

        <b-modal ref="update-list" size="lg" id="modal-upload-update-list" :title="upload_file_title" hide-footer>

            <b-row>
                <b-col class="" cols="12">
                    <span class="mx-2">
                        <img style="width: 1.3em;" :src="getPlatformImage(listSelected.platform)">
                    </span>

                    <span class="mx-2">{{ listSelected.platform }}</span>

                    <span class="mx-2">{{ listSelected.list_name }} - {{ listSelected.total_contacts }} contact{{
                        listSelected.total_contacts > 1 ? 's' : '' }}</span>

                    <span class="text-info pull-right" :data-original-title="getStateText(listSelected.state)"
                        :title="getStateText(listSelected.state)">
                        {{ getStateText(listSelected.state) }} <i aria-hidden="true" class="fa"
                            :class="getStateIcon(listSelected.state)"></i>
                    </span>
                </b-col>
            </b-row>


            <h4 class="text-info mx-2"><i class="ri-file-list-3-fill"></i> Upload Lists</h4>

            <b-row v-if="listSelected && listSelected.platform && getPlatformImage(listSelected.platform)"
                class="row mt-3" align-v="center">

                <b-col class="my-2" cols="12">
                    <h5 class="text-primary mx-2"><i class="ri-file-list-3-fill"></i> Lists</h5>

                    <b-row v-if="listSelected && custom_columns && listsAdded">
                        <b-col cols="6" v-for="(list, i) in listsAdded" :key="i" class="my-1">
                            <b-input-group class="">

                                <b-form-input :id="'input-valid' + i + list.list_id" placeholder="Valid input"
                                    :value="listSelected.list_name + ' - ' + list.list_id + ' - addtional File Contacts - ' + dateFormat(list)"
                                    :disabled="true"></b-form-input>

                                <template #append>
                                    <b-input-group-text>
                                        <strong v-if="getStateText(list.state) == 'Synced'" class="text-success"><i
                                                aria-hidden="true" class="fa"
                                                :class="getStateIcon(list.state)"></i></strong>
                                        <strong v-else-if="getStateText(list.state) == 'In Progress'"
                                            class="text-info"><i aria-hidden="true" class="fa"
                                                :class="getStateIcon(list.state)"></i></strong>
                                        <strong v-else-if="getStateText(list.state) == 'Disconnected'"
                                            class="text-danger"><i aria-hidden="true" class="fa"
                                                :class="getStateIcon(list.state)"></i></strong>
                                        <strong v-else-if="getStateText(list.state) == 'Inactive/deleted'"
                                            class="text-muted"><i aria-hidden="true" class="fa"
                                                :class="getStateIcon(list.state)"></i></strong>
                                    </b-input-group-text>
                                </template>

                            </b-input-group>

                        </b-col>

                        <b-col cols="12" v-if="!listsAdded.length">
                            <span class="mx-2">No Additional contacts file found for {{ listSelected.list_name }}</span>
                        </b-col>
                    </b-row>
                </b-col>

            </b-row>

            <UploadFile v-if="listSelected" :list="listSelected" :platform="listSelected._platform"
                @uploadedList="uploadedNewList" @uploadedNewList="uploadedNewList"></UploadFile>


            <button name="close" value="Close" type="button" class="btn next action-button float-right btn-primary"
                @click="hideModal">
                Close
            </button>
        </b-modal>


        <UpdateContactsInUploadedListModal :list="selected_list" :action="update_contacts_action">
        </UpdateContactsInUploadedListModal>

        <!-- TrackedList TimeLine -->
        <TrackedListTimeLine :list="listSelected"></TrackedListTimeLine>
        
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers';
import ListTimeLine from './timeLogList.vue';
import UploadFile from '@/components/inboxgeek/UploadFile';
import RemoveContactsFile from "@/components/inboxgeek/RemoveContactsFile";
import UpdateContactsInUploadedListModal from "@/components/inboxgeek/modals/UpdateContactsInUploadedListModal";
import { PLATFORMS_ITEMS } from '@/constantes';
import { mapGetters } from "vuex";
import TrackedListTimeLine from "@/components/inboxgeek/modals/TrackedList/TimeLine";

import ListsTable from "./ListsTable"

export default {
    name: 'DtLists',
    components: {
        Spinner1,
        ListTimeLine,
        UploadFile,
        RemoveContactsFile,
        UpdateContactsInUploadedListModal,
        TrackedListTimeLine,
        ListsTable
    },
    data() {
        return {
            id: 'dt-lists',
            account: null,
            from: null,
            to: null,
            filterData: {
                account_id : '',
                status: [],
                platform: [],
                daily_cap: '',
                date_to: '',
                date_from:'',
                auto_refresh: '',
                platform_account: '',
            },
            listSelected: {},
            time_log_title: '',
            upload_file_title: '',
            rowTooltip: null,
            custom_columns: null,
            selected_list:null,
            update_contacts_action:'edit',
            listsAdded: [],
            listsDeleted: [],
            delete_file_title: '',

            logsIsLoading: true
        }
    },
    computed: {
        ...mapGetters('Auth', ['user']),
    },
    mounted() {
        // this.loadFields()
    },
    methods: {
        setAccount(account) {

            this.account = account;

            this.filterData.account_id =account ;
            
            // this.$root.$emit('bv::refresh::table', this.id)
            this.$refs[this.id].forceRenderTable();
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        updateItem(id, data, item = null) {
            var toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.trackedLists.put(id, data)
                .then(result => {
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch((error) => {
                    // this.$root.$emit('bv::refresh::table', this.id)
                    // this.$refs[this.id].forceRenderTable();
                })
                .finally(() => {
                    if (item) {
                        item.state = 'state' in data ? data.state : item.state;
                    }
                    this.$root.$bvToast.toast(toast.message, toast);
                });
        },
        onFiltered(filteredItems) {
        },

        // ACTIONS
        onSwitchAutoRefresh(item, index, input) {
            var listID = item?.id
            var listData = { auto_refresh: input.checked ? 1 : 0 }
            this.updateItem(listID, listData);
        },
        refreshContacts(item, index, button) {
            item.state = 101;
            var listID = item?.id
            var listData = { state: 5 }
            this.updateItem(listID, listData, item);
        },

        removeItem(item, index, button) {
            this.$swal({
                title: 'Are you sure?',
                text: `This action will permenantly delete ${item?.list_name} List`,
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let swalObj = {
                        type: 'success',
                        confirmButtonText: 'OK',
                    }
                    api.dripWarming.lists.delete(item?.id)
                        .then(result => {
                            swalObj.title = 'Success!';
                            swalObj.text = `The ${item.list_name} list has been successfully deleted.`;
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = 'Something went wrong!';
                        })
                        .finally(() => {
                            this.$swal(swalObj)
                            // this.$root.$emit('bv::refresh::table', this.id);
                            this.$refs[this.id].forceRenderTable();
                        });
                }
            })
        },
        getStateText(state) {
            var stateEn = '';
            switch (state) {
                case -1: case -2:
                    stateEn = 'Inactive/deleted';
                    break;
                case -3:
                    stateEn = 'Disconnected';
                    break;
                case 1: case 2:
                    stateEn = 'Synced';
                    break;
                default:
                    stateEn = 'In Progress';
                    break;
            }
            return stateEn;
        },
        getStateIcon(state) {
            var stateEn = '';
            switch (state) {
                case -1: case -2:
                    stateEn = 'fa-exclamation-triangle text-danger';
                    break;
                case -3:
                    stateEn = 'fa-ban text-danger';
                    break;
                case 1: case 2:
                    stateEn = 'fa-check';
                    break;
                default:
                    stateEn = 'fa-spinner';
                    break;
            }
            return stateEn;
        },
        showContacts(state) {
            return (state === 1 || state === 2);
        },
        isRefreshable(state) {
            return (state === 1 || state === 2 || state === -3);
        },
        filterTable(params) {
           // this.filterData.account_id = this.account ;
            this.filterData.status =  params.status ??  [] ;
            this.filterData.platform = params.platform ? _.map(params.platform, 'name') : [] ;
            this.filterData.daily_cap = params.daily_cap ?? '';
            this.filterData.date_to = params.date_to ?? '' ;
            this.filterData.date_from = params.date_from ?? '' ;
            this.filterData.auto_refresh = params.auto_refresh?.code ?? '';
            this.filterData.platform_account = params.platform_account ?? '' ;

            // this.$root.$emit('bv::refresh::table', this.id)
            this.$refs[this.id].forceRenderTable();
            this.$emit('onFinishedFilter');
        },
        canRefreshList(item) {
            try {
                if (!item.platform || item.platform.trim() == '') return true;

                let platform = item.platform.toLowerCase();
                return !['webhook', 'inboxsuite'].includes(platform);
            } catch (e) {
                console.error(e);
                return null;
            }


        },
        timeLogs(item, index) {
            this.listSelected = item;
            this.time_log_title = `Time line list  ${item.list_name}`
            this.$root.$emit("bv::show::modal", "modal-time-log-lists");
        },
        onRowHovered(item, _, event) {
            if (store.getters['Auth/isAdmin'] && [-3].includes(item.state)) {
                let lastLogMessage = '';
                let obj = JSON.parse(JSON.stringify(item.tracked_lists_errors));
                if (obj) {
                    let message = obj[0];
                    if (message && 'errors' in message) {
                        lastLogMessage = message.errors;
                    }
                }

                let options = {
                    title: lastLogMessage,
                    trigger: 'hover',
                }
                $(event.target).tooltip(options)
            }
        },
        onRowUnhovered() {
            this.rowTooltip = null;
        },
        getPlatformImage(platform) {
            try {
                const imgFileName = platform.toLowerCase();
                return require(`@/assets/images/platforms/${imgFileName}.png`);
            } catch (e) {
                return null;
            }
        },
      updateUploadContacts({list,action}){
            this.selected_list = list;
            this.update_contacts_action = action;
        },
        onUploadContacts(item, index) {
            this.custom_columns = null;
            this.listsAdded = [];
            this.listsDeleted = [];

            if (typeof item.platform !== 'undefined' && ['webhook', 'inboxsuite'].includes(item.platform.toLowerCase())) {
                this.listSelected = item;
                this.listSelected._platform = PLATFORMS_ITEMS.find(o => o.name.toLowerCase() == item.platform.toLowerCase());

                this.filteredLists(item);

                this.upload_file_title = `Upload New Contacts to list: ${item.list_name}`
                this.$root.$emit("bv::show::modal", "modal-upload-update-list");
            }

        },
        uploadedNewList(list) {

            this.refreshLists(list);

        },
        deletedList(list) {

            this.refreshLists(list);

        },
        refreshLists(list) {

            this.custom_columns = null;
            this.listsAdded = [];
            this.listsDeleted = [];

            this.custom_columns = list.custom_columns ? JSON.parse(list.custom_columns) : { list_ids: [] }

            this.$nextTick(() => {

                if (typeof this.custom_columns.list_ids !== 'undefined') {
                    this.listsAdded = _.filter(this.custom_columns.list_ids, (o) => { return o.action == 'add'; });
                    this.listsDeleted = _.filter(this.custom_columns.list_ids, (o) => { return o.action == 'delete'; });
                }

            })

        },
        hideModal() {
            this.$root.$emit("bv::hide::modal", "modal-upload-update-list", "#modal-upload-update-list");
        },
        onDeleteContacts(item, index) {

            if (typeof item.platform !== 'undefined' && ['webhook', 'inboxsuite'].includes(item.platform.toLowerCase())) {
                this.listSelected = item;
                this.listSelected._platform = PLATFORMS_ITEMS.find(o => o.name.toLowerCase() == item.platform.toLowerCase());

                this.filteredLists(item);

                this.delete_file_title = `Delete Contacts to list: ${item.list_name}`
                this.$root.$emit("bv::show::modal", "modal-upload-delete-contacts");
            }

        },
        hideModalDelete() {
            this.$root.$emit("bv::hide::modal", "modal-upload-delete-contacts", "#modal-upload-delete-contacts");
        },
        dateFormat(item) {
            if (typeof item?.date_time == 'undefined') return '--';

            var dt = new Date(item.date_time);
            return helper.formatDate(dt)
        },
        filteredLists(item) {

            if (item.custom_columns) {

                this.custom_columns = JSON.parse(item.custom_columns);

                if (typeof this.custom_columns.list_ids !== 'undefined') {
                    this.listsAdded = _.filter(this.custom_columns.list_ids, (o) => { return o.action == 'add'; });
                    this.listsDeleted = _.filter(this.custom_columns.list_ids, (o) => { return o.action == 'delete'; });
                }

            }

        },
        showLogs(item, index) {
            this.listSelected = item;
            this.$root.$emit("bv::show::modal", "modal-list-timeline");
        },
    },
    watch: {
    }
}
</script>
<style>
.table-warning > td {
    background-color: rgba(255, 245, 217, .5) !important;
}

.ig-text-wrap {
    word-wrap: break-word;
}
</style>
