<template>

    <b-container fluid>

    <div v-if="!showTestFlow" class="row my-2 p-4 rounded" style="background:#1cde1c2b;" >

      <div class="col-1">
        <img class="m-auto" style="width: 80px !important;" :src="require('@/assets/images/page-img/img-success.png')" />
      </div>
      <div class="col">
        <h2 class="text-success">Integration Successfully created!</h2>
        <p>Don't forget to test Your integration</p>

        <b-button v-b-modal.modal-new-integration variant="success" @click="showTestFlow = !showTestFlow">
          Integration Test
        </b-button>

      </div>
    </div>






        <b-row class="justify-content-center" v-if="showTestFlow">
            <DripSendFlow :integrationId="integration.id" col=12></DripSendFlow>
        </b-row>


    </b-container>

</template>
<script>

import DripSendFlow from '@/components/inboxgeek/DripSendFlow'
    

export default {
    name: 'AlertIntegrationCreatedWizardModal',
    components: {
      DripSendFlow
    },
    props: {
        integration: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showTestFlow: false
        }
    },
    methods: {},
    watch: {
        showTestFlow: {
            handler(newVal, oldValue) {
                this.$emit('showTestFlow', newVal)
            }
        }
    }
}

</script>