<template>
  <b-container fluid>
    <form>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle class="px-0">Integration</template>

          <template v-if="integration.active == -1 && isAdmin" v-slot:headerAction>
            <b-button ref="btn-restore" variant="primary" @click="onRestore()" :disabled="formModel.active">
              <b-spinner small type="grow" v-show="btnLoading"></b-spinner>
              Restore Integration
            </b-button>
          </template>

          <fieldset :disabled="!remaining && isClient">

            <div class="card-body iq-card-body pt-0 pb-1">

              <b-form-group class="col-md-12 pr-0 mb-0">
                <div
                  class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-right mr-0">
                  <div class="custom-switch-inner">
                    <label class="mr-2">Status</label>
                    <input type="checkbox" class="custom-control-input bg-primary" v-model="formModel.active"
                      id="customSwitch-13" @input="onEnableOrDisable($event.target)">
                    <label class="custom-control-label" for="customSwitch-13" data-on-label="On" data-off-label="Off">
                    </label>
                  </div>
                </div>
              </b-form-group>

              <b-row>
                <b-form-group class="col-md-12" label="Name: " label-for="name">
                  <b-form-input type="text" id="name" v-model="formModel.name" name="name"
                    placeholder="Integration name">
                  </b-form-input>
                </b-form-group>

                <b-form-group class="col-md-12 mb-1">

                  <div
                    class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color mr-0">
                    <div class="custom-switch-inner">
                      <label class="mr-2">Daily limit</label>
                      <input type="checkbox" class="custom-control-input bg-primary" v-model="formModel.dailyLimit"
                        id="dailyLimitSwitch">
                      <label class="custom-control-label" for="dailyLimitSwitch" data-on-label="On"
                        data-off-label="Off">
                      </label>
                    </div>
                  </div>

                </b-form-group>

                <b-form-group v-show="formModel.dailyLimit" class="col-md-12" v-slot="{ ariaDescribedby }">

                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <b-form-radio-group id="radio-group-daily-limit" v-model="formModel.dailyLimitSelected"
                        :options="dailyLimitOptions" :aria-describedby="ariaDescribedby" name="radio-dailyLimitOptions"
                        @input="dailyLimitChange" class="position-relative" />
                      <div class="custom-control-inline small mr-0 position-relative" style="right: -5px; top: -5px;">
                        <span v-show="formModel.dailyLimit && !item.lo_daily_limit_auto_update_id" v-b-tooltip.hover
                          title="Link an LiveOpens Automation to use 'Automated Daily Limit' option" class="small">
                          <i class="ri-information-line fa-2x text-info"></i>
                        </span>
                      </div>
                    </div>
                    <b-form-checkbox v-if="has_hourly_subscription_active" v-model="formModel.has_hourly_limit" name="check-button" switch>
                      Hourly Limit
                    </b-form-checkbox>
                  </div>
                  </b-form-radio-group>

                  <b-form-input v-if="['unlimited', 'fixed'].includes(formModel.dailyLimitSelected)" type="number"
                    id="limit" v-model="formModel.limitPerDay" name="limit" placeholder="limit per day"
                    :disabled="formModel.dailyLimitSelected == 'unlimited'"></b-form-input>

                  <multiselect v-else-if="formModel.dailyLimitSelected == 'auto'"
                    v-model="formModel.loDailyLimitAutoUpdate" deselect-label="Can't remove this value" track-by="code"
                    label="name" placeholder="Select Auto Update Logic" :loading="loDailyLoading"
                    :options="loDailyLimitAutoUpdates" :searchable="true" :allow-empty="false"></multiselect>

                </b-form-group>

              </b-row>

              <b-row>

                <b-form-group v-show="hasOption('time_limit')" class="col-md-12 mt-2">
                  <div
                    class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                    <div class="custom-switch-inner">
                      <label class="mr-2">Time limit</label>
                      <input type="checkbox" class="custom-control-input bg-primary"
                        v-model="formModel.timelimitchecked" id="customSwitch-12"
                        @input="onSwitchLimitTime($event.target)">
                      <label class="custom-control-label" for="customSwitch-12" data-on-label="On" data-off-label="Off">
                      </label>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group v-show="formModel.timelimitchecked && hasOption('time_limit')" class="col-md-12">
                  <b-row>
                    <b-col md="6">
                      <label class="mr-2">From:</label>
                      <b-form-input id="time_limit_from" type="time" v-model="formModel.time_from" value="13:45"
                        :disabled="!formModel.timelimitchecked"></b-form-input>
                      <small v-show="timeFromAlert" class="text-danger">This field is required in its correct time
                        format</small>
                    </b-col>

                    <b-col md="6">
                      <label class="mr-2">To:</label>
                      <b-form-input id="time_limit_to" type="time" value="13:45" v-model="formModel.time_to"
                        :disabled="!formModel.timelimitchecked"></b-form-input>
                      <small v-show="timeToAlert" class="text-danger">This field is required in its correct time
                        format</small>
                    </b-col>
                  </b-row>
                </b-form-group>

              </b-row>

              <b-row>

                <b-form-group class="col-md-6" v-if="showReccurence">
                  <div
                    class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                    <div class="custom-switch-inner mt-4">
                      <label class="mr-2">Recurrent Events</label>
                      <input type="checkbox" class="custom-control-input bg-primary"
                        v-model="formModel.recurrencyChecked" id="customSwitch-14"
                        @input="onSwitchReccurence($event.target)">
                      <label class="custom-control-label" for="customSwitch-14" data-on-label="On" data-off-label="Off">
                      </label>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group class="col-md-6" v-if="formModel.recurrencyChecked">
                  <label class="#" for="input-small">Every {{ formModel.recurrence + ' days' }}</label>
                  <b-form-input type="number" min="1" value="0" id="input-small" size="sm"
                    placeholder="Enter reccurence" v-model="formModel.recurrence">
                  </b-form-input>
                </b-form-group>

              </b-row>

            </div>

          </fieldset>

        </iq-card>
      </b-col>

      <fieldset :disabled="!remaining && isClient">

        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle class="px-0">Tracked Lists</template>
            <div class="card-body iq-card-body pt-0 pb-1">

              <div v-if="showMailchimpList">

                <ListStepMailchimp :lists="lists" :platform_id="integration.platform_id"
                  :oAccountId="formatOrganization" :auth2="{}" :integration="integration"
                  :platform="integration.platform" :listAlready="listAlready" @listSelected="listSelected"
                  @segmentSelected="segmentSelected">
                </ListStepMailchimp>
              </div>

              <div v-else-if="showBlastableList">

                <label style="display:flex; justify-content: space-between;">
                  <span>{{ 'List/Segment' }}</span>
                </label>

                <multiselect tag-placeholder="Empty" placeholder="List" label="name" track-by="code" v-model="list"
                  :options="lists" return="code" :multiple="true" @select="" @remove="" allow-empty>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">

                      <span class="option__title">

                        <span v-if="props.option.type">

                          <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                          <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                        </span>

                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                      </span>

                    </div>

                  </template>

                </multiselect>

                <small id="alertLists" class="text-danger"></small>
              </div>

              <b-form-group v-else-if="integration.platform.name.toLowerCase() == 'beehiiv'" label="Custom Fields"
                label-for="list" class="col-md-12">

                <MultiselectLists class="p-0" :platform="integration.platform.name" :propLists="integration.lists"
                  :propItem="integration" @onSelect="updateValue"
                  :disabled="cantUpdateLists" :isMultiple="false">
                </MultiselectLists>

                <label for="">Custom field value:</label>
                <b-input v-model="customField.code" @keyup="beehiivInputValue"></b-input>
                <div class="alert alert-info my-2" style="color: #22768a;background-color: #e5faff9e;padding: 1rem;">
                  <p class="m-0"> Retrieve only contacts with the given specified value in the given custom field
                    attribute. </p>
                </div>
              </b-form-group>

              <div v-else-if="showBeehiivList">
                <BeehiivUpdateListField
                    :lists="integration.lists"
                    :platform_id="integration?.platform?.id"
                    @listSelected="onListsUpdated"
                ></BeehiivUpdateListField>
              </div>

              <!-- external input component -->
              <MultiselectLists v-else class="p-0" :platform="integration.platform.name" :propLists="integration.lists"
                :propItem="integration" @onSelect="onListsUpdated" @onRemove="onListsUpdated"
                :disabled="cantUpdateLists">
                <template slot="option" slot-scope="props">
                  <div class="option__desc">

                    <span class="option__title">

                      <span v-if="props.option.type">

                        <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                        <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                      </span>

                      <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                      <span style="font-wight:bold;"> {{ props.option.name }} </span>

                      <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                    </span>

                  </div>

                </template>
              </MultiselectLists>


            </div>
          </iq-card>
        </b-col>

      </fieldset>


      <fieldset :disabled="!remaining && isClient">


        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle class="px-0">Platform</template>
            <div class="card-body iq-card-body pt-0 pb-1">

              <b-form-group class="col-md-12" label="Platform" label-for="platform">
                <PlatformsMultiselect class="p-0" :platform="integration.platform.name" :disabledProp="true">
                </PlatformsMultiselect>
              </b-form-group>

              <b-form-group class="col-md-12" label="Account" label-for="account" v-if="formModel.account">
                <b-form-input v-model="formModel.account" id="account" type="text" placeholder="Account"></b-form-input>
              </b-form-group>

              <b-form-group class="col-md-12" label="Token" label-for="token" invalid-feedback="Token is required">
                <b-form-textarea rows="4" v-model="formModel.key" id="token" size="lg" placeholder="Api key...">
                </b-form-textarea>
              </b-form-group>

            </div>
          </iq-card>
        </b-col>

      </fieldset>


      <b-form-group class="col-md-12">
        <b-button ref="btn-update" variant="primary" @click="update()"
          :disabled="integration.active == -1 || isClientAndOutOfEvents()">
          <b-spinner small type="grow" v-show="btnLoading"></b-spinner>
          Update
        </b-button>
      </b-form-group>
    </form>

  </b-container>
</template>

<script>
import { sofbox } from '@/config/pluginInit'
import Multiselect from 'vue-multiselect'
import _ from 'lodash';
import api from '@/api/RestClient'
import { helper } from '@/helpers'
import MultiselectLists from '@/components/inboxgeek/inputs/MultiselectLists'
import PlatformsMultiselect from '@/components/inboxgeek/inputs/PlatformsMultiselect'
import UploadFile from '@/components/inboxgeek/UploadFile'
import ListStepMailchimp from '@/components/inboxgeek/modals/IntgrationModal/ListStepMailchimp.vue'
import ListStepBlastable from '@/components/inboxgeek/modals/IntgrationModal/ListStepBlastable.vue'

import { SERVICES } from "@/constantes";

import Vue from 'vue'
import { mapGetters } from 'vuex'
import BeehiivInput from "@/components/inboxgeek/platforms/inputs/BeehiivInput";
import BeehiivCustomFieldsInput from "@/components/inboxgeek/fileds/BeehiivCustomFieldsInput";
import BeehiivUpdateListField from "@/views/Integration/modals/fieldsets/BeehiivUpdateListField";

Vue.directive("disable-form", {
  componentUpdated: function (el, binding) {
    if (!binding.value) return;
    const tags = ["input", "button", "textarea", "select"];
    tags.forEach(tagName => {
      const nodes = el.getElementsByTagName(tagName);
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].disabled = true;
        nodes[i].tabIndex = -1;
      }
    });
  }
});

const EXCEPT_CHECK_SAME = ['lists']

export default {
  name: 'ModalUpdateIntegration',
  components: {
    BeehiivUpdateListField,
    BeehiivCustomFieldsInput,
    BeehiivInput,
    Multiselect,
    MultiselectLists,
    PlatformsMultiselect,
    UploadFile,
    ListStepMailchimp,
    ListStepBlastable,
  },
  props: {
    item: {
      type: [Object],
      default: () => null,
    },
    form: {
      type: [Object],
      default: () => null,
    }
  },
  data() {
    return {
      customField: {
        code: null,
        name: null
      },
      customFieldForm: {
        code: null,
        name: null
      },
      selectedCustomField: null,
      integration: this.item,
      formModel: {
      },
      formInit: {},
      btnLoading: false,
      loDailyLoading: false,
      lists: [],
      timeToAlert: false,
      timeFromAlert: false,
      remaining: null,
      currentPlan: null,
      listAlready: null,
      showMailchimpList: false,
      showBlastableList: false,
      options:{},
      list: [],
      dailyLimitOptions: [
        // { text: 'Unlimited', value: 'unlimited' },
        { text: 'Custom Value', value: 'fixed' },
        { text: 'Automated Daily Limit', value: 'auto', disabled: this.item && !this.item.lo_automation },
      ],
      loDailyLimitAutoUpdates: [],
      has_hourly_subscription_active: false
    }
  },
  mounted() {
    sofbox.index();
    this.fetchOptions();
    this.getLoDailyLimitAutoUpdates();
    this.getConsumptionDetails();
    this.getFormData();
    if (this.integration.platform.name.toLowerCase() == 'beehiiv') {
      this.beehiivOptions()
    }
    this.checkHourlyLimit();

  },
  methods: {
    checkHourlyLimit() {
      const subscriptions = this.item.account.subscriptions;
      const subscription = _.find(subscriptions, function(s){
        return s.status == 1 && s.service_id == 1 && s.end_at == null
      })
      if (subscription) {
        const options = JSON.parse(subscription.options)
        this.has_hourly_subscription_active = 'hourly_limit' in options && options.hourly_limit;
      }

      return 1;
    },
    beehiivInputValue(){
      this.customFieldForm.name = this.customField.name;
      this.customFieldForm.code = `${this.customField.name}[${this.customField.code}]`

    },
    beehiivOptions() {
      let list = _.first(this.integration.lists)
      const split_1 = list.list_id.split('[')[1];
      const split_2 = split_1.split(']')[0];
      this.customField.code = split_2;
      this.customField.name = list.list_name;
      this.beehiivInputValue();
    },
    getFormData() {
      let form = this.getInitForm()

      this.formInit = Vue.util.extend({}, form);
      this.formModel = form;
    },
    onEnableOrDisable($event) {
      if ($event.checked && this.isClient && !this.remaining) {
        this.showAlertRemaining()
      }
    },
    onSwitchLimitTime($event) {
      if (!$event.checked) {
        this.formModel.time_from = null
        this.formModel.time_to = null
      } else {
        this.formModel = this.setFormLimitTime(this.integration, this.formModel)
      }
    },
    onSwitchReccurence($event) {
      if (!$event.checked) {
        this.formModel.recurrence = 0
      } else {
        this.formModel.recurrence = this.integration.recurrence
      }
    },
    onSwitchLimitPerDay($event) {
      if (!$event.checked) {
        this.formModel.limitPerDay = null
      } else {
        this.formModel.limitPerDay = this.integration.limit > -1 ? this.integration.limit : null
      }

    },
    getInitForm(integration = null) {
      if (!integration) {
        integration = this.integration
      }

      let form = {
        active: integration.active > 0 ? true : false,
        name: integration.name,
        key: integration.key,
        p_id: integration.platform_id,
        platform_id: integration.platform_id,
        account: integration.platform_account,
        list_id: integration.list_id,
        code: integration.id,
        recurrence: integration.recurrence,
        recurrencyChecked: integration.recurrence != 0,
        limitPerDay: (integration.limit > -1) ? integration.limit : null,
        limitDaychecked: false,
        timelimitchecked: false,
        lists: integration.platform.name.toLowerCase() == 'beehiiv' ? [this.customFieldForm] : integration.lists,
        dailyLimit: this.integration.limit != -1 || this.integration.lo_daily_limit_auto_update_id,
        dailyLimitSelected: this.integration.lo_daily_limit_auto_update_id ? this.dailyLimitOptions[1].value : this.dailyLimitOptions[0].value,
        loDailyLimitAutoUpdate: this.integration.lo_daily_limit_auto_update_id ?? null,
        has_hourly_limit: this.integration.has_hourly_limit == 1 ? true : false,
      }

      // if (form.limitPerDay > 0) form.limitDaychecked = true
      if(this.integration.lo_daily_limit_auto_update_id) {
        form.dailyLimitSelected = this.dailyLimitOptions[1].value
      } else {
        form.dailyLimitSelected = this.dailyLimitOptions[0].value
      }

      // else {
      //   form.dailyLimitSelected = this.dailyLimitOptions[0].value
      // }

      form = this.setFormLimitTime(integration, form)

      return form
    },
    setFormLimitTime(integration, form) {
      var timeLimit = JSON.parse(integration.time_limit)
      if (helper.validateTime(timeLimit.time_limit_to) && helper.validateTime(timeLimit.time_limit_from)) {
        form.timelimitchecked = true
        form.time_to = timeLimit.time_limit_to;
        form.time_from = timeLimit.time_limit_from;
      }

      if (form.time_from == '--' || typeof form.time_from == "undefined") form.time_from = null;
      if (form.time_to == '--' || typeof form.time_to == "undefined") form.time_to = null;

      return form
    },
    update() {
      this.btnLoading = true;

      let platform_name = this.integration.platform.name.toLowerCase()

      var toast = {
        title: 'Error',
        message: 'Something went wrong!',
        variant: 'danger'
      }

      const id = this.formModel.code;
      let data = this.formModel;

      if (!data.dailyLimit) {
        data.limitPerDay = -1;
        data.loDailyLimitAutoUpdate = null;
      } else if(data.dailyLimitSelected == 'auto' && data.loDailyLimitAutoUpdate) {
        data.loDailyLimitAutoUpdate = this.formModel.loDailyLimitAutoUpdate.code;
      } else {
        data.loDailyLimitAutoUpdate = null;
      }


      if (!this.listsMustBeUpdate()) {
        data = _.omit(data, ['lists'])
      }

      if (['mailchimp', 'blastable'].includes(platform_name) && this.list.length) {
        data.lists = this.list
      }

      api.dripWarming.integrations.put(id, data)
        .then(result => {
          toast.title = 'Success'
          toast.variant = 'success'
          toast.message = result.message

          // Emit modal event
          this.$emit('modalHide', {data: result.data, state: result.success})
        })
        .catch(function (error) {
          // handle error
          if (error.response) {
            message = 'something went wrong!'
          }
        })
        .finally(() => {
          this.$root.$bvToast.toast(toast.message, { title: toast.title, variant: toast.variant });
          this.btnLoading = false;
        });
    },
    onRestore() {
      this.btnLoading = true;
      let toast = { title: 'Error', message: 'Something went wrong!', variant: 'danger' }

      const id = this.formModel.code;

      api.integrations.restore(id)
        .then(result => {
          toast.title = 'Success'
          toast.variant = 'success'
          toast.message = result.message
          this.integration = _.omit(result.data, ['key'])
          this.getFormData()
          this.$emit('modalHide', result.success)
        })
        .finally(() => {
          this.$root.$bvToast.toast(toast.message, { title: toast.title, variant: toast.variant });
          this.btnLoading = false;
        });
    },
    fetchOptions(){


            if(this.userSubscriptions[SERVICES.LIVE_OPENS.id]){

            this.options = JSON.parse(this.userSubscriptions[SERVICES.LIVE_OPENS.id].options);
            }
        },
    onListsUpdated(items) {

      if (Array.isArray(items) && items.length > 0) this.formModel.lists = items
    },
    listsMustBeUpdate() {
      let status = this.formModel.lists.length !== this.formInit.lists.length

      this.formModel.lists.forEach(list => {
        let list_code = typeof list?.list_id !== "undefined" ? list.list_id : list.code
        let newList = _.find(this.formInit.lists, function (o) {
          let code = typeof o?.list_id !== "undefined" ? o.list_id : o.code
          return code == list_code;
        });

        if (!newList) status = true
      });

      let platform_name = this.integration.platform.name.toLowerCase()
      if (['blastable'].includes(platform_name)) {
        this.$refs['btn-update'].disabled = false
      }

      if (['beehiiv'].includes(platform_name)) {
        status = true
      }
      return status
    },
    getConsumptionDetails() {
      if (this.isClient) {
        api.stats.getConsumptionDataService()
          .then(response => {
            this.consumption_details = response.data;
            this.remaining = response.data.remaining;
            this.currentPlan = response.data.plan;
          })
          // .finally(() => {
          //   if (!this.remaining) {
          //     this.showAlertRemaining()
          //   }
          // })
      }
    },
    showAlertRemaining() {
      let date = this.currentPlan ? this.currentPlan.next_billing_date : '--';

      let alertMessage = `It\'s appears you are out of events until [${date}]. If you\'d like to activate your overconsumption feature to continue sending events until your next billing cycle, please visit your dashboard`;

      this.$swal({
        title: 'Uh, oh...',
        text: alertMessage,
        type: 'info',
        confirmButtonText: 'Got it!',
        showCloseButton: true,
      })
    },
    listSelected(list) {
    // return helper.listSelected(list)
      if (list) {
        this.list = [{
          'code': list.code,
          'name': list.name,
          'type': 'list'
        }];
      } else {
        this.list = [];
      }
    },
    segmentSelected(segments, list) {
      // return helper.segmentSelected(segments, list)
      if (list) {

        this.list = segments.map((segment) => {
          return {
            'code': segment.code + '-' + list.code,
            'name': segment.name,
            'type': 'segment'
          };
        })

      } else {
        this.list = [];
      }
    },
    formatOrganization() {
      return { code: this.integration.account_id, name: this.integration.account }
    },
    fetchLists() {

      let platform_id = this.integration.platform_id;

      let listsParams = {
        "platform_name": 'mailchimp',
        "entities": 'lists',
        "platform_id": platform_id,
        "list_id": this.integration.lists[0].id,
        "p_id": platform_id
      }

      api.platforms.fetchServices(listsParams).then((response) => {

        let lists = response.data.map((list) => {
          return {
            'code': list.id,
            'name': list.name
          };
        });
        this.lists = lists

        this.listAlready = this.lists[0]
        this.listAlready.list_name = this.integration.lists[0].list_name

        this.showMailchimpList = false
        this.$nextTick(() => {
          this.showMailchimpList = true
        })
      }).catch((error) => {
        console.log('failed to fetch lists');
      });

    },
    fetchBlastableLists(withSegment = false) {

      let platform_id = this.integration.platform_id;
      // console.log('integration', this.integration); return;

      this.isLoading = true;
      api.platforms.fetchServices({
        "platform_name": 'blastable',
        "entities": "lists",
        "account": this.formatOrganization().code,
        "platform_id": platform_id,
        "platform": this.integration.platform_id,
        // "segment": withSegment,
      }).then((response) => {

        if (response.data.data.records && response.data.data.records.length) {

          this.lists = response.data.data.records.map((list) => {
            return {
              'code': list.general.list_uid,
              'name': list.general.name,
              'type': 'list'
            };
          });

        }

      }).catch((error) => {

        console.log('Failed to fetch lists');

      }).finally(() => {
        this.isLoading = false;

        if(this.$refs['btn-update']) this.$refs['btn-update'].disabled = false;

        if (withSegment) {
          this.fetchBlastableSegments();
        }
        else {
          this.showBlastableList = false
          this.$nextTick(() => {
            this.showBlastableList = true
          })
        }
      });
    },
    fetchBlastableSegments() {

      this.isLoading = true;
      const that = this;

      let platform_id = this.integration.platform_id;
      var segments = [];

      api.platforms.fetchServices({
        "platform_name": 'blastable',
        "entities": "segments",
        "account": this.formatOrganization().code,
        "platform_id": platform_id,
      }).then((response) => {

        if (response.data.data.records && response.data.data.records.length) {
          segments = response.data.data.records.map((segment) => {
            let item = {
              'code': segment.segment_uid,
              'name': segment.name,
              'type': 'segment'
            };

            // Lists and segments in same input
            this.lists.push(item);
            return item
          });
        }

      }).catch((error) => {
        console.log('Failed to fetch segments');
      }).finally(() => {
        this.isLoading = false;

        this.listAlready = this.integration.lists[0];

        let currListOrSegment = _.find(that.lists, function (o) { return o.code == that.listAlready.list_id && o.name == that.listAlready.list_name });

        if (currListOrSegment) {
          this.list = [currListOrSegment];
        }

        this.showBlastableList = false
        this.$nextTick(() => {
          this.showBlastableList = true
        });

      });
    },
    hasOption(option_name){

        if (this.options ) {
            return this.options[option_name] == 1;
        }

        return false;
    },
    isClientAndOutOfEvents() {
      return this.isClient && !helper.checkIfAccountSubscriptionByService(SERVICES.LIVE_OPENS.id);
    },
    dailyLimitChange($event) {
      if ($event == 'unlimited') {
        this.formModel.limitPerDay = null;
      } else if($event == 'fixed') {
        this.formModel.limitPerDay = this.integration.limit > -1 ? this.integration.limit : null
      } else if ($event == 'auto') {
        this.formModel.limitPerDay =  null;
        // TODO Set LoDailyLimitAutoUpdate
      }

    },
    updateValue(e) {
      this.customField.name = e.name;
      this.beehiivInputValue();
    },
    getLoDailyLimitAutoUpdates() {

      this.loDailyLoading = true;

      let  params = {account: this.isAdmin ? this.formatOrganization().code : null};

      api.loDailyLimitAutoUpdates.getNames(params)
        .then((response) => {

          this.loDailyLimitAutoUpdates = response.data.map((dailyLimit) => {
            let item = {
              'code': dailyLimit.id,
              'name': dailyLimit.name
            };

            return item
          });

          if(this.item.lo_daily_limit_auto_update_id) {
            let loDailyLimitAutoUpdateSelected = _.find(this.loDailyLimitAutoUpdates, (o) => o.code == this.item.lo_daily_limit_auto_update_id);

            if(loDailyLimitAutoUpdateSelected) {
              this.formModel.loDailyLimitAutoUpdate = loDailyLimitAutoUpdateSelected;
            }
          }
        })
        .finally(() => {
          this.loDailyLoading = false;
       })
    }
  },
  computed: {
    showBeehiivList() {
      return this.integration.platform.name == "Beehiiv";
    },
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      isClient: 'Auth/isClient',
      userSubscriptions : 'Auth/UserSubscriptions'
    }),
    isSameInit() {
      return _.isEqual(_.omit(this.formModel, EXCEPT_CHECK_SAME), _.omit(this.formInit, EXCEPT_CHECK_SAME));
    },
    cantUpdateLists() {
      return ['aweber', 'emailoctopus'].includes(this.integration.platform.name.toLowerCase())
    },
    showTags() {
      return ['Aweber', 'EmailOctopus'].includes(this.formModel.platform)
    },
    showReccurence() {
      // For [Klaviyo - Maropost - Active Campaign - Webhook] only ('inbox for testing')
      let esp = this.integration.platform.name.toLowerCase();
      return ['klaviyo', 'maropost', 'activecampaign', 'webhook', 'inbox'].includes(esp);
    }
  },
  watch: {
    "formModel": {
      handler(newVal, oldVal) {
        this.formModel.account = this.integration.platform.account;
        this.formModel.key = this.integration.platform.key;
        if (this.listsMustBeUpdate() || !this.isSameInit) {
          this.$refs['btn-update'].disabled = false
        } else if (this.integration.platform.name.toLowerCase() == 'mailchimp' && this.integration.active !== -1) {
          this.$refs['btn-update'].disabled = false
        } else {
          this.$refs['btn-update'].disabled = (this.btnLoading || (this.isSameInit && !this.listsMustBeUpdate()))
        }

        if (this.integration.platform.name.toLowerCase() == 'mailchimp') {
          this.fetchLists()
        } else if (this.integration.platform.name.toLowerCase() == 'blastable') {
          this.fetchBlastableLists(true);
        }
      },
      deep: true
    },
  },
}
</script>

<style>
#radio-group-daily-limit div.custom-control.custom-control-inline.custom-radio:nth-child(2) {
  margin-right: 0 !important;
}

#radio-group-daily-limit label.info-daily-limit::before, #radio-group-daily-limit label.info-daily-limit::after {
  content: none !important;
}

</style>

